import constants from '@/utilities/constants';

const getBoardPropThreshold = (isSprint) =>
  isSprint
    ? constants.fullyLoadedSprintBoardPropThreshold
    : constants.fullyLoadedBoardPropThreshold;

const hasSufficientProperties = (object, threshold) => Object.keys(object).length > threshold;

const getBoardItemType = (boardId) => (boardId === constants.epicsBoardId ? 'epics' : 'cards');

const hasItemsInLists = (board) => {
  if (!board?.lists?.length) return false;

  const itemType = getBoardItemType(board.id);
  return board.lists.some((list) => {
    const items = list?.[itemType];
    return Array.isArray(items) && items.length > 0;
  });
};

export const isBoardFullyLoaded = (board, isSprint = false) => {
  // *KNOWN BUG: boards with lists, where all lists do not contain any cards will be always fetched
  // TODO: to be fixed with this card: https://app.superthread.com/superthread/card-10112-isboardfullyloaded-check-not-working-properly
  if (!board) return false;

  const threshold = getBoardPropThreshold(isSprint);
  return hasSufficientProperties(board, threshold) && hasItemsInLists(board);
};

export const isPageFullyLoaded = (page) => {
  if (!page) return false;

  const threshold = constants.fullyLoadedPagePropThreshold;
  return hasSufficientProperties(page, threshold);
};
