const getDraftCardTitleKey = (
  teamID: string,
  projectId: string,
  boardId: string,
  listId: string,
  cardId: string
) => `${teamID}-${projectId}-${boardId}-${listId}-${cardId}-title`;

const getDraftCardTitleKeyForList = (
  teamID: string,
  projectId: string,
  boardId: string,
  listId: string,
  cardId: string
) => `${teamID}-${projectId}-${boardId}-${listId}-title`;

const getDraftCardDescriptionKey = (
  teamID: string,
  projectId: string,
  boardId: string,
  listId: string,
  cardId: string
) => `${teamID}-${projectId}-${boardId}-${listId}-${cardId}-description`;

const getDraftCommentKey = (teamID: string, sectionId: string) =>
  `${teamID}-${sectionId}-create-comment`;

const getDraftEditCommentKey = (teamID: string, commentId: string, sectionId: string) =>
  `${teamID}-${sectionId}-${commentId}-edit-comment`;

const getDraftChecklistKey = (
  teamID: string,
  projectId: string,
  boardId: string,
  listId: string,
  cardId: string
) => `${teamID}-p${projectId}-b${boardId}-l${listId}-c${cardId}-checklist`;

const getDraftEditChecklistKey = (
  teamID: string,
  projectId: string,
  boardId: string,
  listId: string,
  cardId: string,
  checklistId: string
) => `${teamID}-p${projectId}-b${boardId}-l${listId}-c${cardId}-cl${checklistId}-edit-checklist`;

const getDraftChecklistItemKey = (
  teamID: string,
  projectId: string,
  boardId: string,
  listId: string,
  cardId: string,
  checklistId: string
) => `${teamID}-p${projectId}-b${boardId}-l${listId}-c${cardId}-cl${checklistId}-new-checklistItem`;

const getDraftEditChecklistItemKey = (
  teamID: string,
  projectId: string,
  boardId: string,
  listId: string,
  cardId: string,
  checklistId: string,
  checklistItemId: string
) =>
  `${teamID}-p${projectId}-b${boardId}-l${listId}-c${cardId}` +
  `-cl${checklistId}-cli${checklistItemId}-edit-checklistItem`;

const getChildCardCreationDraftKey = (teamID: string, parentCardId: string) =>
  `${teamID}-${parentCardId}-child-card-draft`;

// used for child card creation inside template creation
const getTemplateChildCardCreationDraftKey = (
  teamID: string,
  templateID: string,
  isQuickCard: boolean = false
) =>
  teamID +
  (templateID ? `-${templateID}` : '') +
  (isQuickCard ? '-quick-card' : '-template') +
  '-child-card-create-draft';

// used for child card edit inside template creation
const getTemplateChildCardEditDraftKey = (
  teamID: string,
  templateID: string,
  index: string = '',
  isQuickCard: boolean = false
) =>
  teamID +
  (templateID ? `-${templateID}` : '') +
  (isQuickCard ? '-quick-card' : '-template') +
  `-child-card-edit-draft-${index}`;

const getQuickCardCreationDraftKey = (teamID: string) => `${teamID}-quick-card-draft`;
const getCreateCardTemplateDraftKey = (teamID: string) => `${teamID}-create-card-template-draft`;
const getCreatePageTemplateDraftKey = (teamID: string) => `${teamID}-create-page-template-draft`;
const getCreateNoteTemplateDraftKey = (teamID: string) => `${teamID}-create-note-template-draft`;

const getParentPageForAddKey = (teamID: string) => `${teamID}-parent-page-for-add`;

const setDraft = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const getDraft = (key: string) => localStorage.getItem(key);

const removeDraft = (key: string) => localStorage.removeItem(key);

const localBoardLayoutKey = (teamId: string) => `${teamId}-board-layout`;

const hideEmptyGroupByBoardKey = (teamId: string, boardId: string) =>
  `${teamId}-hideEmptyGroupByBoard-${boardId}`;
const showWeekendsByBoardKey = (teamId: string, boardId: string) =>
  `${teamId}-showWeekendsByBoard-${boardId}`;

const myWorkSortByKey = (teamId: string) => `${teamId}-myWorkSortBy`;

export {
  getDraftCardTitleKey,
  getDraftCardTitleKeyForList,
  getDraftCardDescriptionKey,
  getDraftCommentKey,
  getDraftEditCommentKey,
  getDraftChecklistKey,
  getDraftEditChecklistKey,
  getDraftChecklistItemKey,
  getDraftEditChecklistItemKey,
  setDraft,
  getDraft,
  removeDraft,
  getChildCardCreationDraftKey,
  getTemplateChildCardCreationDraftKey,
  getTemplateChildCardEditDraftKey,
  getQuickCardCreationDraftKey,
  getCreateCardTemplateDraftKey,
  getCreatePageTemplateDraftKey,
  getParentPageForAddKey,
  localBoardLayoutKey,
  hideEmptyGroupByBoardKey,
  showWeekendsByBoardKey,
  myWorkSortByKey,
  getCreateNoteTemplateDraftKey,
};
