/**
 * Page module vuex mutations
 * @packageDocumentation
 * @category Store
 */
import { MutationTree } from 'vuex';
import { Page, PageState as State } from '@/types';
import getDefaultPageState from './getDefaultPageState';
import { browserStorageSetItem, browserStorageGetItem } from '@/utilities/browser-storage.util';
import { getParentPageForAddKey } from '@/utilities/localDrafts';
import { isTokenExpired } from '@/utilities/jwtUtilities';
import { toRaw } from 'vue';

const mutations: MutationTree<State> = {
  setArchivedPages(state, data = []) {
    state.archivedPages = data;
  },

  addPageToArchivedPages(state, pageId) {
    state.archivedPages.push(pageId);
  },

  removePageFromArchivedPages(state, pageId) {
    const index = state.archivedPages.findIndex((id) => id === pageId);

    if (index > -1) {
      state.archivedPages.splice(index, 1);
    }
  },

  setPage(state, page = {}) {
    const existingPage = state.pages[page.id];
    let expired = true;
    if (existingPage?.collaboration?.token) {
      expired = isTokenExpired(existingPage.collaboration.token);
    }

    state.page = {
      ...existingPage,
      ...page,
      collaboration: expired ? toRaw(page.collaboration) : toRaw(existingPage.collaboration),
    };
  },

  updatePageMeta(state, page = {}) {
    state.page = {
      ...state.page,
      ...(page.bookmarked && { bookmarked: page.bookmarked }),
      ...(page.id && { id: page.id }),
      ...(page.is_public && { is_public: page.is_public }),
      ...(page.members && { members: page.members }),
      ...(page.project_id && { project_id: page.project_id }),
      ...(page.public_settings && { public_settings: page.public_settings }),
      ...(page.team_id && { team_id: page.team_id }),
      ...(page.time_created && { time_created: page.time_created }),
      ...(page.time_updated && { time_updated: page.time_updated }),
      ...(page.user_id && { user_id: page.user_id }),
      ...(page.icon && { icon: page.icon }),
      ...(page.cover_image && { cover_image: page.cover_image }),
      ...(page.collaboration && { collaboration: page.collaboration }),
    };

    const currentPageId = state.currentPageID;
    if (!state.pages[currentPageId]) {
      state.pages[currentPageId] = state.page;
    }
    state.pages[currentPageId] = {
      ...state.page,
      ...{
        bookmarked: page.bookmarked,
        id: page.id,
        is_public: page.is_public,
        members: page.members,
        project_id: page.project_id,
        public_settings: page.public_settings,
        team_id: page.team_id,
        time_created: page.time_created,
        time_updated: page.time_updated,
        user_id: page.user_id,
        cover_image: page.cover_image,
        icon: page.icon,
      },
    };
  },

  setCurrentPageID(state, id) {
    state.currentPageID = id;
  },

  setCurrentPageMode(state, mode) {
    state.currentPageMode = mode;
  },

  setPagesEntity(state, entity) {
    state.pagesEntity = entity;
  },

  loadingPages(state, payload) {
    state.loadingPages = payload;
  },

  removePageFromListByIndex(state, pageIndex) {
    if (state.pages[pageIndex]) {
      state.pages.splice(pageIndex, 1);
    }
  },

  pageCreating(state, payload) {
    state.pageCreating = payload;
  },

  setImageUploadPopup(state, payload) {
    state.imageUploadPopup = payload;
  },

  setCoverImageUploadPopup(state, payload) {
    state.coverImageUploadPopup = payload;
  },

  setPageIconUploadPopup(state, payload) {
    state.pageIconUploadPopup = payload;
  },

  setInsertLinkPopup(state, payload) {
    state.insertLinkPopup = payload;
  },

  resetPageState(state) {
    Object.assign(state, getDefaultPageState());
  },

  setPageVersions(state, payload) {
    state.pageVersions = payload;
  },

  setProjectPages(state, { projectId, pagesIds }) {
    state.projectPages[projectId] = pagesIds;
  },

  setPages(state, pages) {
    const updatedPages: any = {};
    Object.keys(pages).forEach((pageId) => {
      const page = pages[pageId];
      const existingPage = state.pages[pageId];
      if (!existingPage || page.time_updated > existingPage.time_updated) {
        updatedPages[pageId] = page;
      } else {
        updatedPages[pageId] = {
          ...existingPage,
          ...page,
          content: existingPage.content ?? page.content,
          time_updated: existingPage.time_updated ?? page.time_updated,
        };
      }
    });
    const newPages = { ...state.pages, ...updatedPages };

    state.pages = newPages;
  },

  addPageToProjectPages(state, { pageId, projectId }) {
    if (!state.projectPages[projectId]) {
      state.projectPages[projectId] = [];
    }
    state.projectPages[projectId].push(pageId);
  },

  setNewPageCreating(state, value) {
    state.newPageCreating = value;
  },

  localUpdatePageProp(state, { pageId, propertyName, value }) {
    if (state.page.id === pageId) {
      state.page[propertyName] = value;
    }
    if (state.pages[pageId]) {
      state.pages[pageId][propertyName] = value;
    }
  },

  removePageFormProjectPages(state, { pageId, projectId }) {
    if (state.projectPages[projectId]) {
      const index = state.projectPages[projectId].findIndex((id: any) => id === pageId);

      if (index > -1) {
        state.projectPages[projectId].splice(index, 1);
      }
    }
  },

  localRemovePageProp(state, { pageId, propertyName }) {
    if (state.page.id === pageId) {
      delete state.page[propertyName];
    }

    delete state.pages[pageId][propertyName];
  },

  addPageToPagesList(state, { page }) {
    const existingPage = state.pages[page.id];
    if (!existingPage || page.time_updated > existingPage.time_updated) {
      state.pages[page.id] = page;
    } else {
      state.pages[page.id] = {
        ...existingPage,
        ...page,
        content: existingPage.content ?? page.content,
        time_updated: existingPage.time_updated ?? page.time_updated,
      };
    }
  },

  deleteFromPages(state, { id }) {
    delete state.pages[id];
  },

  commentPopupVisible(state, payload) {
    state.inlineCommentPopup = payload;
  },

  addPageToFlatPages(state, page) {
    if (!state.pages[page.id]) {
      state.pages[page.id] = {};
    }
    const newPage = {
      ...page,
      ...{
        bookmarked: page.bookmarked,
        id: page.id,
        is_public: page.is_public,
        members: page.members,
        project_id: page.project_id,
        team_id: page.team_id,
        time_created: page.time_created,
        time_updated: page.time_updated,
        user_id: page.user_id,
        cover_image: page.cover_image,
        icon: page.icon,
      },
    };
    state.pages[page.id] = newPage;
  },

  setLastSidebarItemAdded(state, id) {
    state.lastSidebarItemAdded = id;
  },

  setParentPageIdForAdd(state, id) {
    state.parentPageIdForAdd = id;
  },

  setProjectPagesTree(state, { projectId, pagesTree }) {
    const draftPageProjectId = state.sidebarDraftPage?.projectId;
    const draftPageParentId = state.sidebarDraftPage?.parent;
    // Check if the project ID matches the ID of the currently selected draft page in the sidebar.
    if (projectId === draftPageProjectId) {
      // Get draft page if it exists in the local storage.
      const draftPage: any =
        browserStorageGetItem(
          getParentPageForAddKey(browserStorageGetItem('currentTeamId') || '')
        ) ?? {};
      // Update the project pages tree with the provided pages tree for the specified project ID.
      state.projectPagesTree[projectId] = {
        ...(state.projectPagesTree[projectId] ?? {}),
        ...(pagesTree ?? {}),
      };
      // Check if there are children in the draft page's parent in the current project pages tree.
      if (Object.keys(draftPage).length > 0) {
        const projecPagesTree = state.projectPagesTree?.[draftPageProjectId] ?? {};
        // Retrieve the current children of the draft page's parent.
        if (projecPagesTree) {
          const currentChildren =
            state.projectPagesTree?.[draftPageProjectId]?.[draftPageParentId]?.children ?? [];
          // Add the children of the draft page's parent to the current children and update the tree.
          state.projectPagesTree[draftPageProjectId][draftPageParentId] = {
            ...(state.projectPagesTree[draftPageProjectId][draftPageParentId] ?? {}),
            children: [...currentChildren, draftPage.id],
          };
        }
      }
      return;
    }
    state.projectPagesTree[projectId] = pagesTree;
  },

  setProjectPageOrderTree(state, { projectId, pagesTree }) {
    state.projectPagesTree[projectId] = pagesTree;
  },

  setProjectArchivedTree(state, { projectId, archiveTree }) {
    state.archivePagesTree[projectId] = archiveTree;
  },

  setProjectArchivePages(state, archivePages) {
    const newPages = { ...state.archivePages, ...archivePages };
    state.archivePages = newPages;
  },

  setProjectArchivePagesTree(state, { projectId, archivedPagesTree }) {
    state.archivePagesTree[projectId] = archivedPagesTree;
  },

  localUpdatePageOrderPosition(state, { projectId, parentId, newOrder }) {
    state.projectPagesTree[projectId][parentId].children = newOrder;
  },

  localAddPageInPageTree(state, { projectId, page, order, position = -1 }) {
    const newPageTree = {
      ...state.projectPagesTree[projectId],
      ...{ [page.id]: page },
    };
    state.projectPagesTree[projectId] = newPageTree;

    if (!order) return; // parent root order

    if (state.projectPagesTree[projectId][page.parent]) {
      if (state.projectPagesTree[projectId][page.parent]?.children?.includes(page.id)) return;
      if (state.projectPagesTree[projectId][page.parent].children) {
        if (position > -1) {
          state.projectPagesTree[projectId][page.parent].children.splice(position, 0, page.id);
          return;
        }
        state.projectPagesTree[projectId][page.parent].children.push(page.id);
      } else {
        state.projectPagesTree[projectId][page.parent].children = [page.id];
      }
    } else {
      // Check if the parent of the given page is not 'root'.
      if (page.parent !== 'root') {
        // Update the project pages tree for the specified project ID, adding the parent and its children.
        state.projectPagesTree[projectId] = {
          ...(state.projectPagesTree[projectId] ?? {}),
          [page.parent]: {
            ...(state.projectPagesTree[projectId][page.parent] ?? {}),
            children: [page.id],
          },
        };
        return;
      }
      const rootChildren = state.projectPagesTree[projectId]?.root?.children || [];
      if (rootChildren?.includes(page.id)) return;
      state.projectPagesTree[projectId] = {
        ...newPageTree,
        root: {
          id: 'root',
          children: [...rootChildren, page.id],
        },
      };
    }
  },

  localAddPageInArchivePageTree(state, { projectId, page }) {
    const newPageTree = {
      ...state.archivePagesTree[projectId],
      ...{ [page.id]: page },
    };

    const newPages = { ...state.archivePages, ...{ [page.id]: state.pages[page.id] } };
    state.archivePages = newPages;

    state.archivePagesTree[projectId] = newPageTree;

    if (state.archivePagesTree[projectId][page.parent]) {
      if (state.archivePagesTree[projectId][page.parent].children) {
        state.archivePagesTree[projectId][page.parent].children.push(page.id);
      } else {
        state.archivePagesTree[projectId][page.parent].children = [page.id];
      }
    } else {
      state.archivePagesTree[projectId] = {
        ...newPageTree,
        root: { id: 'root', children: [page.id] },
      };
    }
  },

  localRemovePagesFromTree(state, { projectId, pages }) {
    const pagesForDelete = pages;

    pagesForDelete.forEach((page: any) => {
      delete state.projectPagesTree[projectId][page.id];
    });
  },

  localRemovePagesFromArchiveTree(state, { projectId, pages }) {
    const pagesForDelete = pages;

    pagesForDelete.forEach((page: any) => {
      if (state.archivePagesTree[projectId] && page?.id) {
        delete state.archivePagesTree[projectId][page.id];
      }
    });
  },

  localRemovePageFromPageOrder(state, { projectId, pageId }) {
    if (!state.projectPagesTree[projectId]) return;
    const page = state.projectPagesTree[projectId][pageId];
    const pageParentId = page?.parent;
    const parentsPageChildren = state.projectPagesTree[projectId][pageParentId]?.children;
    if (!parentsPageChildren) return;
    if (!page) return;

    delete state.projectPagesTree[projectId][pageId];

    const pageForRemoveIndex = parentsPageChildren.findIndex((i: any) => i === pageId);
    if (pageForRemoveIndex > -1) {
      parentsPageChildren.splice(pageForRemoveIndex, 1);
    }
    if (parentsPageChildren.length === 0) {
      const newPageTree = {
        ...state.projectPagesTree[projectId],
        ...{
          [page.id]: {
            id: page.id,
            parent: pageParentId,
          },
        },
      };
      state.projectPagesTree[projectId] = newPageTree;
    }
  },

  removePageFromArchivedParentOrder(state, { projectId, pageParentId, pageId }) {
    const pageForRemoveIndex = state.archivePagesTree[projectId][pageParentId].children.findIndex(
      (i: any) => i === pageId
    );
    if (pageForRemoveIndex > -1) {
      state.archivePagesTree[projectId][pageParentId].children.splice(pageForRemoveIndex, 1);
    }
  },

  localRemovePageFromArchivedTree(state, { projectId, pageId }) {
    const page = state.archivePagesTree[projectId][pageId];
    if (!page) return;
    const pageParentId = page.parent;

    delete state.archivePagesTree[projectId][pageId];
    delete state.archivePages[pageId];

    const pageForRemoveIndex = state.archivePagesTree[projectId][pageParentId].children.findIndex(
      (i: any) => i === pageId
    );
    if (pageForRemoveIndex > -1) {
      state.archivePagesTree[projectId][pageParentId].children.splice(pageForRemoveIndex, 1);
    }
    if (state.archivePagesTree[projectId][pageParentId].children.length === 0) {
      const newPageTree = {
        ...state.archivePagesTree[projectId],
        ...{
          [page.id]: {
            id: page.id,
            parent: pageParentId,
          },
        },
      };
      state.archivePagesTree[projectId] = newPageTree;
    }
  },

  localAddPageToPageOrder(state, { projectId, pageId }) {
    const page = state.projectPagesTree[projectId][pageId];
    const pageParentId = page.parent;
    if (state.projectPagesTree[projectId][pageParentId].children.includes(pageId)) return;
    state.projectPagesTree[projectId][pageParentId].children.push(pageId);
  },

  localMovePageInPageTree(
    state,
    { projectIdTo, projectIdFrom, page, parentIdTo, parentIdFrom, position }
  ) {
    if (!state.projectPagesTree[projectIdTo]) return;
    if (
      !state.projectPagesTree[projectIdTo][parentIdTo] ||
      !state.projectPagesTree[projectIdFrom][parentIdFrom]
    )
      return;
    const pageForChange = page;
    const pagesObject = { ...state.projectPagesTree };
    delete pagesObject[projectIdFrom][page.id];
    state.projectPagesTree = pagesObject;
    const pageForRemoveIndex = state.projectPagesTree[projectIdFrom][
      parentIdFrom
    ].children.findIndex((i: any) => i === page.id);
    state.projectPagesTree[projectIdFrom][parentIdFrom].children.splice(pageForRemoveIndex, 1);

    pageForChange.parent = parentIdTo;
    state.projectPagesTree[projectIdTo] = {
      ...state.projectPagesTree[projectIdTo],
      [page.id]: pageForChange,
    };
    if (state.projectPagesTree[projectIdTo][parentIdTo]?.children) {
      state.projectPagesTree[projectIdTo][parentIdTo].children.splice(position, 0, page.id);
    } else {
      state.projectPagesTree[projectIdTo][parentIdTo].children = [page.id];
    }
  },

  localAddProjectInPageTree(state, projectId) {
    const newPageObje = {
      ...state.projectPagesTree[projectId],
      root: { id: 'root', children: [] },
    };
    state.projectPagesTree[projectId] = newPageObje;
  },

  setSidebarPageDraft(state, { draftPageItem, teamId }) {
    state.sidebarDraftPage = draftPageItem;
    browserStorageSetItem(getParentPageForAddKey(teamId), draftPageItem);
  },

  setSetParentPageExpand(state, { id, value }) {
    state.parentPageExpanded[id] = value;
    let existing = localStorage.getItem('parentPageExpanded');
    existing = existing ? JSON.parse(existing) : {};
    // @ts-ignore: Object is possibly 'null'.
    existing[id] = value;
    localStorage.setItem('parentPageExpanded', JSON.stringify(existing));
  },

  setPageUploadPopup(state, value) {
    state.pageUploadPopup = value;
  },

  removePageUploadPopup(state) {
    state.pageUploadPopup = {};
  },

  setPageRenamingId(state, value) {
    state.pageRenamingId = value;
  },

  addQuickPageFileToUpdate(state, file) {
    state.quickPageFilesToUpdate.push(file);
  },

  removeQuickPageFilesToUpdate(state) {
    state.quickPageFilesToUpdate = [];
  },

  setPageTemplates(state, templatesArray) {
    const flatTemplates: { [key: string]: any } = {};
    templatesArray.forEach((template: any) => {
      flatTemplates[template.id] = template;
    });
    const newTemplates = { ...flatTemplates };
    state.pageTemplates = newTemplates;
  },

  setPageTemplate(state, template) {
    state.pageTemplates[template.id] = template;
  },

  replacePageTemplateId(state, { localId, template }) {
    const newTemplates = { ...state.pageTemplates };
    delete newTemplates[localId];
    newTemplates[template.id] = template;
    state.pageTemplates = newTemplates;
  },

  deletePageTemplate(state, templateId) {
    const newTemplates = { ...state.pageTemplates };
    delete newTemplates[templateId];
    state.pageTemplates = newTemplates;
  },

  setPageTemplateDraft(state, template) {
    const newTemplate = {
      page_content: template.page_content,
      page_title: template.page_title,
      icon: template.icon,
      project_ids: template.project_ids,
      title: template.title,
    };
    state.pageTemplateDraft = newTemplate;
  },

  updatePageTemplateDraftField(state, { fieldName, value }) {
    state.pageTemplateDraft[fieldName] = value;
  },

  clearPageTemplateDraft(state) {
    state.pageTemplateDraft = {
      title: '',
      icon: {},
      project_ids: [],
      page_title: '',
      page_content: '',
    };
  },

  setHighlightedPageLevelComments(state, commentsIds) {
    state.highlightedPageLevelComments = commentsIds;
  },

  setPageLevelCommentInputActive(state, value) {
    state.pageLevelCommentInputActive = value;
  },

  managePageTemplateSelectedSpaces(state, spaceId) {
    const { project_ids } = state.pageTemplateDraft;
    const index = project_ids.indexOf(spaceId);
    if (index > -1) {
      project_ids.splice(index, 1);
    } else {
      project_ids.push(spaceId);
    }
  },

  setPageEditorDisplay(state, payload) {
    state.pageEditorDisplay = payload;
  },

  removePage(state, pageId) {
    delete state.pages[pageId];
  },

  setPageIdForMove(state, pageId) {
    state.pageIdForMove = pageId;
  },
};

export default mutations;
