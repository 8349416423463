import { computed } from 'vue';
import { TeamMemberRole } from '@superthread-com/api';
import { useStore } from 'vuex';

export default function useCurrentRole() {
  const store = useStore();

  const currentRole = computed<TeamMemberRole>(() => {
    return store.getters.getCurrentRole;
  });

  const isOwner = computed(() => {
    return currentRole.value === TeamMemberRole.Owner;
  });

  const isAdmin = computed(() => {
    return currentRole.value === TeamMemberRole.Admin;
  });

  const isMember = computed(() => {
    return currentRole.value === TeamMemberRole.Member;
  });

  const isGuest = computed(() => {
    return currentRole.value === TeamMemberRole.Guest;
  });

  const isGuestReader = computed(() => {
    return currentRole.value === TeamMemberRole.GuestReader;
  });

  /**
   * Is true if the user has one of the roles with elevated
   * permissions. Only use this for the "lowest" elevated role
   * (i.e. this could be true for admins and owners). Check for "higher"
   * elevated roles specifically (i.e. isOwner for owner-specific UI,
   * isElevated for UI that could be seen by any admin or owner).
   *
   * NOTE: In 'Free' mode this should return true for all users. Currently it does not.
   */
  const isElevated = computed(() => {
    return isOwner.value || isAdmin.value;
  });

  return {
    currentRole,
    isOwner,
    isAdmin,
    isMember,
    isGuest,
    isElevated,
    isGuestReader,
  };
}
