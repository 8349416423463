import { getSprintKey } from '@/utilities/sprintKeys';

export default {
  computed: {
    listOfViewResults() {
      return this.$store.getters.getListOfViewResults;
    },
    currentViewResultsCount() {
      return this.$store.getters.getCurrentViewTotalCount;
    },
    cardCountLabel() {
      const cardLabel = this.translate(
        this.currentViewResultsCount === 1 ? 'card' : 'cards'
      ).toLowerCase();
      return this.isMobile && this.$route.name === this.$constants.routeNames.view ? '' : cardLabel;
    },
  },

  methods: {
    preloadNewViewResults(id, card_filters = this.$store.getters.getViewFilters) {
      if (id in this.listOfViewResults) return;

      this.fetchNewViewResults(card_filters).then(({ cards, count }) => {
        this.localAddViewResultsToList({
          cards,
          count,
          id,
        });
        cards.forEach((card) => {
          this.postFetchViewResults(card);
        });
      });
    },

    preloadViewResults(viewId) {
      if (viewId in this.listOfViewResults) return;

      this.fetchViewResults(viewId).then(({ cards, count }) => {
        this.localAddViewResultsToList({
          cards,
          count,
          id: viewId,
        });
        cards.forEach((card) => {
          this.postFetchViewResults(card);
        });
      });
    },

    fetchNewViewResults(card_filters = this.$store.getters.getViewFilters) {
      return this.$store.dispatch('fetchNewViewResults', {
        card_filters,
        type: 'card',
      });
    },

    fetchViewResults(viewId) {
      return this.$store.dispatch('fetchViewResults', viewId);
    },

    setViewResults({ cards, count }) {
      this.$store.dispatch('setViewResults', cards);
      this.$store.dispatch('currentViewTotalCount', count);
    },

    localViewResults(id) {
      return this.$store.getters.getViewResultsFromList(id) || {};
    },

    setViewResultsFromLocal(id) {
      const viewToSelect = [
        ...this.$store.getters.getPublicViews,
        ...this.$store.getters.getPrivateViews,
      ].find((view) => view.id === id);
      if (viewToSelect) this.$store.dispatch('setSelectedView', viewToSelect);

      const { cards = [], count = 0 } = this.localViewResults(id);
      this.setViewResults({ cards, count });
    },

    localAddViewResultsToList(payload) {
      this.$store.dispatch('localAddViewResultsToList', payload);
    },

    postFetchViewResults(card) {
      this.$store.dispatch('addCardToCards', { card });
      if (card.sprint_id) {
        this.fetchSprint(card.sprint_id, card.project_id);
        return;
      }
      this.fetchBoard(card.board_id);
    },

    fetchBoard(board_id) {
      const flatBoard = this.$store.getters.getFlatBoards[board_id] || {};
      if (!this.$isBoardFullyLoaded(flatBoard)) {
        this.$store.dispatch('fetchBoard', { boardId: board_id }).then(({ board }) => {
          this.fetchTags(board.project_id);
        });
      }
    },

    fetchSprint(sprintId, projectId) {
      const sprintKey = getSprintKey(projectId, sprintId);
      const sprint = this.$store.getters.getFlatBoards[sprintKey] || {};
      if (this.$isBoardFullyLoaded(sprint, true)) return;
      this.$store.dispatch('getSprint', { sprintId, projectId });
    },

    fetchTags(project_id) {
      if (!this.$store.getters.projectTags[project_id]) {
        this.$store.dispatch('fetchTags', { projectId: project_id });
      }
    },
  },
};
