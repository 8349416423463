/**
 * Pages module actions
 * @packageDocumentation
 * @category Store
 */
import { nanoid } from 'nanoid';
import { ActionTree } from 'vuex';
import { PageState as State } from '@/types';
import { authGet, authPut, authDelete, authPatch, api } from '@/utilities/api';
import { translate } from '@/utilities';

const activePromises: { [key: string]: { [key: string]: Promise<any> } } = {
  pages: {},
  projectPages: {},
};
import { OnboardingActionType } from '@/types/onboardingActions';
import { ResourceType } from '@/types/resources';

const actions: ActionTree<State, any> = {
  loadArchivedPages({ commit, getters }) {
    return api.pages
      .getPages(getters.getTeamID, {
        archived: true,
      })
      .then((r) => r.data)
      .then(({ pages }) => {
        const pagesObject: any = {};
        const pagesIds: any = [];

        pages?.forEach((p: any) => {
          pagesIds.push(p.id);
          pagesObject[p.id] = p;
        });

        commit('setPages', pagesObject);
        commit('setArchivedPages', pagesIds);
      });
  },

  /**
   * Archive page
   * https://api.superthread.com/v1/{team_id}/pages/{page_id}
   */
  archivePage({ commit, getters, dispatch }, { pageId, projectId }) {
    const { getRoutes, getTeamID } = getters;

    const timeNow = Math.floor(Date.now() / 1000);
    const page = getters.getPageById(pageId);
    const pageFromTree = getters.getProjectPagesTree(projectId)[page.id];

    commit('addPageToArchivedPages', pageId);

    commit('localUpdatePageProp', {
      pageId,
      propertyName: 'archived',
      value: {
        time_archived: timeNow,
        user_id: getters.getUserId,
        parent_page_id: pageFromTree.parent,
      },
    });

    commit('removePageFormProjectPages', {
      pageId,
      projectId,
    });

    commit('localAddPageInArchivePageTree', { projectId, page: pageFromTree });

    commit('localRemovePageFromPageOrder', { projectId, pageId });

    commit('localRemovePagesFromTree', { projectId, pages: [pageFromTree] });

    // remove page from team project
    commit('removeProjectPage', { pageId, projectId });

    return authPut(`${getRoutes.pages}/v1/${getTeamID}/pages/${pageId}/archive`).catch((error) => {
      commit('removePageFromArchivedPages', pageId);
      commit('localUpdatePageProp', {
        pageId,
        propertyName: 'archived',
        value: {
          time_archived: null,
          user_id: '',
        },
      });
      commit('addPageToProjectPages', {
        pageId,
        projectId,
      });
      // add page to team project
      commit('addProjectPage', { page: { id: pageId, title: page.title }, projectId });
      throw error;
    });
  },

  unarchivePage({ commit, getters, dispatch }, { pageId, projectId }) {
    const { getRoutes, getTeamID } = getters;
    const page = getters.getPageById(pageId);
    const archivedTime = page.archived.time_archived;
    const parentPageId = page.archived.parent_page_id;

    commit('removePageFromArchivedPages', pageId);
    commit('localUpdatePageProp', {
      pageId,
      propertyName: 'archived',
      value: {
        time_archived: null,
        user_id: '',
        parent_page_id: parentPageId,
      },
    });
    commit('addPageToProjectPages', {
      pageId,
      projectId,
    });

    // add page to team project
    commit('addProjectPage', { page: { id: pageId, title: page.title }, projectId });

    return authDelete(`${getRoutes.pages}/v1/${getTeamID}/pages/${pageId}/archive`).catch(
      (error) => {
        commit('addPageToArchivedPages', pageId);
        commit('removePageFormProjectPages', {
          pageId,
          projectId,
        });
        commit('localUpdatePageProp', {
          pageId,
          propertyName: 'archived',
          value: {
            time_archived: archivedTime,
            user_id: getters.getUserId,
            parent_page_id: parentPageId,
          },
        });

        // remove page from team project
        commit('removeProjectPage', { pageId, projectId });
        throw error;
      }
    );
  },

  loadPages({ commit, getters }, projectIdParam) {
    let projectId = projectIdParam;
    if (!projectIdParam) {
      commit('loadingPages', true);
      projectId = getters.getCurrentProjectId;
    }
    if (activePromises.projectPages[projectId] !== undefined) {
      return activePromises.projectPages[projectId];
    }
    activePromises.projectPages[projectId] = api.pages
      .getPages(getters.getTeamID, {
        project_id: projectId,
      })
      .then((r) => r.data)
      .then(({ pages }) => {
        const pagesIds: any = [];
        const pagesObject: any = {};

        pages?.forEach((p: any) => {
          pagesIds.push(p.id);
          const newPage = {
            ...(getters.getPageById(p.id) || {}),
            ...p,
          };
          pagesObject[p.id] = newPage;
        });

        commit('setPages', pagesObject);
        commit('setProjectPages', { projectId, pagesIds });
        return pages;
      })
      .catch(() => {})
      .finally(() => {
        if (getters.loadingPages) {
          commit('loadingPages', false);
        }
        delete activePromises.projectPages[projectId];
      });
    return activePromises.projectPages[projectId];
  },

  fetchPage({ commit, getters }, pageId) {
    return api.pages.getPage(getters.getTeamID, pageId).then(({ data }) => {
      const { page } = data;

      if (!page?.id) return;

      commit('setPages', {
        [page.id]: page,
      });
      commit('setProjectPages', {
        projectId: page.project_id,
        pagesIds: [page.id],
      });

      return page;
    });
  },

  handlePageCreated({ commit, dispatch }, { page }) {
    commit('addPageToFlatPages', page);
    commit('addPageToProjectPages', {
      pageId: page.id,
      projectId: page.project_id,
    });
    dispatch('localAddPageInPageTree', {
      projectId: page.project_id,
      pageId: page.id,
    });
    dispatch('localAddPageInProject', {
      projectId: page.project_id,
      page,
    });
  },

  // https://api.superthread.com/v1/{team_id}/pages
  createNewPage({ getters, dispatch }, { page, noContent = false }) {
    const pageToCreate = {
      title: page.title || '',
      ...(page.content && { content: page.content }),
      ...(page.project_id && { project_id: page.project_id }),
      ...(getters.getParentPageIdForAdd !== '' && {
        parent_page_id: getters.sidebarDraftPage?.parent || getters.getParentPageIdForAdd,
      }),
      ...(page.cover_image && {
        ...(page.cover_image.src && { src: page.cover_image.src }),
        ...(page.cover_image.blurhash && { blurhash: page.cover_image.blurhash }),
      }),
      ...(page.icon && {
        ...(page.icon.src && { src: page.icon.src }),
        ...(page.icon.blurhash && { blurhash: page.icon.blurhash }),
      }),
      ...(page.user_id && { user_id: page.user_id }),
      ...(page.is_public && { is_public: page.is_public }),
      schema: page.schema ?? getters.pagesSchema,
    };

    return api.pages
      .createPage(getters.getTeamID, pageToCreate)
      .then(({ data }) => {
        dispatch('completeOnboardingActions', [OnboardingActionType.CreatePage]);
        return data;
      })
      .catch((error) => {
        dispatch('pageCreating', false);
        throw error;
      });
  },

  debounceUpdatePage({ getters, commit, dispatch }, { pageId, payload }) {
    const { getTeamID } = getters;
    return api.pages
      .updatePage(getTeamID, pageId, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        const error = response.data;
        if (error.message === 'oldSchema') {
          // add ui notification
          commit('addUiNotification', {
            message: translate('updateAppToEditPage'),
            duration: 4000,
            id: nanoid(4),
          });
        }
        throw error;
      });
  },

  /**
   * Update existing page
   * https://api.superthread.com/v1/{team_id}/pages/{page_id}
   * @param page page object
   * @returns {Promise}
   */
  updatePageProps({ getters, dispatch }, { pageId, page }) {
    const { getTeamID } = getters;
    return api.pages.updatePage(getTeamID, pageId, page).then((data) => {
      return data;
    });
  },

  // https://api.superthread.com/v1/{team_id}/pages/{page_id}
  loadPage({ state, getters, dispatch }, pageId = state.currentPageID) {
    return authGet(`${getters.getRoutes.pages}/v1/${getters.getTeamID}/pages/${pageId}`)
      .then(({ data }) => {
        dispatch('setCurrentPageMode', 'view');
        return data;
      })
      .catch((/* error */) => {
        dispatch('setCurrentPageMode', 'notfound');
        dispatch('setPage', {});
      });
  },
  fetchPageData({ getters, commit }, { teamId, resourceId }) {
    const existingPromise = activePromises.pages[resourceId];
    if (existingPromise !== undefined) return existingPromise;
    const promise = authGet(`${getters.getRoutes.pages}/v1/${teamId}/pages/${resourceId}`)
      .then(({ data }) => {
        if (teamId === getters.getTeamID) {
          commit('addPageToPagesList', { page: data.page });
        }
        return data.page;
      })
      .finally(() => {
        delete activePromises.pages[resourceId];
      });
    activePromises.pages[resourceId] = promise;
    return promise;
  },

  loadPageVersions({ state, getters, commit }) {
    return api.pages
      .getPageVersions(getters.getTeamID, state.currentPageID)
      .then(({ data }) => {
        commit('setPageVersions', data.pageVersions);
      })
      .catch((error) => {
        console.log('error ', error);
      });
  },

  setPageVersions({ commit }, payload) {
    commit('setPageVersions', payload);
  },

  setCurrentPageID({ commit }, pageId) {
    commit('setCurrentPageID', pageId);
  },

  setCurrentPageMode({ commit }, mode) {
    commit('setCurrentPageMode', mode);
  },

  setPage({ commit }, page) {
    commit('setPage', page);
  },

  updatePageMeta({ commit }, page) {
    commit('updatePageMeta', page);
  },

  setPagesEntity({ commit }, entity) {
    commit('setPagesEntity', entity);
  },

  localMovePageToProject({ commit }, { pageId, projectIdAdd, projectIdRemove }) {
    commit('removePageFormProjectPages', {
      pageId,
      projectId: projectIdRemove,
    });

    commit('addPageToProjectPages', {
      pageId,
      projectId: projectIdAdd,
    });

    commit('localUpdatePageProp', {
      pageId,
      propertyName: 'project_id',
      value: projectIdAdd,
    });

    commit('localRemoveBoardOrPageInProject', {
      projectId: projectIdRemove,
      itemId: pageId,
      section: 'pages',
    });
  },

  movePageToProject(
    { getters, dispatch, commit },
    { pageId, projectId, currentProjectId, parentPageId = 'root', position = -1 }
  ) {
    const newProjectPagesLength = getters.getProjectPages(projectId).length;

    dispatch('localMovePageToProject', {
      pageId,
      projectIdAdd: projectId,
      projectIdRemove: currentProjectId,
    });

    return dispatch('updatePageProps', {
      pageId,
      page: {
        project_id: projectId,
        parent_page_id: parentPageId,
        ...(position > -1 && newProjectPagesLength && { position }),
      },
    }).catch((err) => {
      dispatch('localMovePageToProject', {
        pageId,
        projectIdAdd: currentProjectId,
        projectIdRemove: projectId,
      });
    });
  },

  localMovePageInPageTree(
    { commit, dispatch },
    { projectIdTo, projectIdFrom, page, parentIdTo, parentIdFrom, position }
  ) {
    commit('localMovePageInPageTree', {
      projectIdTo,
      projectIdFrom,
      page,
      parentIdTo,
      parentIdFrom,
      position,
    });
    if (projectIdTo !== projectIdFrom) {
      dispatch('localMovePageToProject', {
        pageId: page.id,
        projectIdAdd: projectIdTo,
        projectIdRemove: projectIdFrom,
      });

      commit('localUpdatePageProp', {
        pageId: page.id,
        propertyName: 'project_id',
        value: projectIdTo,
      });
    }
  },

  /**
   * Set comments count in current page
   * @param {number} commentsCount
   */

  pageCreating({ commit }, payload) {
    commit('pageCreating', payload);
  },

  setImageUploadPopup({ commit }, payload) {
    commit('setImageUploadPopup', payload);
  },

  setCoverImageUploadPopup({ commit }, payload) {
    commit('setCoverImageUploadPopup', payload);
  },

  setPageIconUploadPopup({ commit }, payload) {
    commit('setPageIconUploadPopup', payload);
  },

  setInsertLinkPopup({ commit }, payload) {
    commit('setInsertLinkPopup', payload);
  },

  resetPageState({ commit }) {
    commit('resetPageState');
  },

  getPageTitleByTeamIdAndPageId({ getters }, { teamId, resourceId }) {
    return authGet(`${getters.getRoutes.pages}/v1/${teamId}/pages/${resourceId}`)
      .then(({ data }) => data.page)
      .catch((error) => {
        throw error;
      });
  },

  togglePagePublicFlag({ getters }, { isPublic, pageId }) {
    const { getTeamID } = getters;
    return authPatch(`${getters.getRoutes.pages}/v1/${getTeamID}/pages/${pageId}`, {
      is_public: isPublic,
    });
  },

  setPages({ commit }, pages) {
    commit('setPages', pages);
  },

  addPageToProjectPages({ commit }, { pageId, projectId }) {
    commit('addPageToProjectPages', { pageId, projectId });
  },

  setNewPageCreating({ commit }, value) {
    commit('setNewPageCreating', value);
  },

  localUpdatePageProp({ commit }, { pageId, propertyName, value }) {
    commit('localUpdatePageProp', {
      pageId,
      propertyName,
      value,
    });
  },

  duplicatePage({ getters }, { pageId, teamId, projectId, title, position = -1 }) {
    const { getParentPageIdForAdd } = getters;
    const page = {
      project_id: projectId,
      title,
      parent_page_id: getParentPageIdForAdd,
      ...(position > -1 && { position }),
    };
    return api.pages.copyPage(teamId, pageId, page).then(({ data }) => data);
  },
  addPageToPagesList({ commit }, { localId, page }) {
    commit('addPageToPagesList', {
      localId,
      page,
    });
  },
  deleteFromPages({ commit }, { id, page }) {
    commit('deleteFromPages', {
      id,
      page,
    });
  },

  commentPopupVisible({ commit }, payload) {
    commit('commentPopupVisible', payload);
  },

  addPageToFlatPages({ commit }, payload) {
    commit('addPageToFlatPages', payload);
  },
  setProjectPages({ commit }, { projectId, pagesIds }) {
    commit('setProjectPages', { projectId, pagesIds });
  },

  setLastSidebarItemAdded({ commit }, id) {
    commit('setLastSidebarItemAdded', id);
  },

  setParentPageIdForAdd({ commit }, id) {
    commit('setParentPageIdForAdd', id);
  },

  setProjectPagesTree({ commit }, payload) {
    commit('setProjectPagesTree', payload);
  },

  setProjectPageOrderTree({ commit }, { projectId, pagesTree }) {
    commit('setProjectPageOrderTree', { projectId, pagesTree });
  },

  setProjectArchivedTree({ commit }, payload) {
    commit('setProjectArchivedTree', payload);
  },

  setProjectArchivePages({ commit }, archivePages) {
    commit('setProjectArchivePages', archivePages);
  },

  setProjectArchivePagesTree({ commit }, { projectId, archivedPagesTree }) {
    commit('setProjectArchivePagesTree', { projectId, archivedPagesTree });
  },

  localAddPageInPageTree({ commit, getters }, { projectId, pageId, position = -1 }) {
    const parentPageId = getters.sidebarDraftPage?.id
      ? getters.sidebarDraftPage?.parent
      : getters.getParentPageIdForAdd;
    const page = {
      id: pageId,
      parent: parentPageId,
    };
    commit('localAddPageInPageTree', {
      projectId,
      page,
      order: true,
      position,
    });
  },

  localRemovePagesFromTree({ commit, getters }, { projectId, pages, archiveTree = false }) {
    if (archiveTree) {
      commit('localRemovePagesFromArchiveTree', {
        projectId,
        pages,
      });
      return;
    }
    commit('localRemovePagesFromTree', {
      projectId,
      pages,
    });
  },

  removePageFromArchivedParentOrder({ commit, getters }, { projectId, pageParentId, pageId }) {
    commit('removePageFromArchivedParentOrder', { projectId, pageParentId, pageId });
  },

  localRemovePageFromArchivedTree({ commit, getters }, { projectId, pageId }) {
    commit('localRemovePageFromArchivedTree', {
      projectId,
      pageId,
    });
  },

  localAddPageInArchivePageTree({ commit, getters }, { projectId, page }) {
    commit('localAddPageInArchivePageTree', {
      projectId,
      page,
    });
  },

  localAddPageInProjectPageTree({ commit }, { projectId, page, order = false }) {
    commit('localAddPageInPageTree', { projectId, page, order });
  },

  localUpdatePageOrderPosition({ commit }, { projectId, parentId, newOrder }) {
    commit('localUpdatePageOrderPosition', {
      projectId,
      parentId,
      newOrder,
    });
  },

  setSidebarPageDraft({ commit, getters }, draftPageItem) {
    const { getTeamID } = getters;
    commit('setSidebarPageDraft', { draftPageItem, teamId: getTeamID });
  },

  movePageHandler(
    { commit, getters, dispatch },
    {
      destinationProjectId,
      originalProjectId,
      destinationPages,
      originalPages,
      destinationPageOrder,
      originalPageOrder,
    }
  ) {
    const desinationPageTree: any = {};
    const orginalPageTree: any = {};
    const destinationPagesIds: any = [];

    [...(destinationPageOrder || [])].forEach((p) => {
      desinationPageTree[p.id] = p;
    });
    [...(originalPageOrder || [])].forEach((p) => {
      orginalPageTree[p.id] = p;
    });

    if (destinationPages?.length) {
      destinationPages.forEach((p: any) => {
        destinationPagesIds.push(p.id);
        commit('localUpdatePageProp', {
          pageId: p.id,
          propertyName: 'project_id',
          value: destinationProjectId,
        });
      });
    }
    if (destinationPageOrder?.length) {
      dispatch('localUpdateTeamProjectField', {
        projectId: destinationProjectId,
        fieldName: 'page_order',
        fieldValue: destinationPageOrder,
      });
    }

    if (originalPageOrder?.length) {
      dispatch('localUpdateTeamProjectField', {
        projectId: originalProjectId,
        fieldName: 'page_order',
        fieldValue: originalPageOrder,
      });
    }

    if (destinationPages != undefined) {
      dispatch('localUpdateTeamProjectField', {
        projectId: destinationProjectId,
        fieldName: 'pages',
        fieldValue: destinationPages,
      });
    }

    if (originalPages != undefined) {
      dispatch('localUpdateTeamProjectField', {
        projectId: originalProjectId,
        fieldName: 'pages',
        fieldValue: originalPages,
      });
    }

    commit('setProjectPagesTree', {
      projectId: destinationProjectId,
      pagesTree: { ...getters.getProjectPagesTree(destinationProjectId), ...desinationPageTree },
    });

    commit('setProjectPagesTree', {
      projectId: originalProjectId,
      pagesTree: { ...getters.getProjectPagesTree(originalProjectId), ...orginalPageTree },
    });
  },

  setSetParentPageExpand({ commit }, { id, value }) {
    commit('setSetParentPageExpand', { id, value });
  },

  removePageFromBookmarked({ commit }, pageId) {
    commit('removePageFromBookmarked', pageId);
  },

  setPageUploadPopup({ commit }, value) {
    commit('setPageUploadPopup', value);
  },

  removePageUploadPopup({ commit }) {
    commit('removePageUploadPopup');
  },

  setPageRenamingId({ commit }, value) {
    commit('setPageRenamingId', value);
  },

  refreshPageCollaboration({ commit, getters }, { id }) {
    if (id === 'newpage') return;
    const { getTeamID } = getters;
    return api.pages
      .getPageCollaboration(getTeamID, id)
      .then(({ data }) => {
        commit('localUpdatePageProp', {
          pageId: id,
          propertyName: 'collaboration',
          value: data.collaboration,
        });
      })
      .catch((error) => {});
  },

  addQuickPageFileToUpdate({ commit }, file) {
    commit('addQuickPageFileToUpdate', file);
  },

  removeQuickPageFilesToUpdate({ commit }) {
    commit('removeQuickPageFilesToUpdate');
  },

  removePageIconOrCover({ getters, commit }, { page, propertyName }) {
    const value = page[propertyName];
    let query = propertyName;
    if (propertyName === 'cover_image') query = 'cover';
    commit('localUpdatePageProp', {
      pageId: page.id,
      propertyName,
      value: {
        src: '',
        blurhash: '',
        type: '',
      },
    });
    return authDelete(
      `${getters.getRoutes.pages}/v1/${getters.getTeamID}/pages/${page.id}/${query}`
    ).catch(() => {
      commit('localUpdatePageProp', {
        pageId: page.id,
        propertyName,
        value,
      });
    });
  },

  fetchPageTemplates({ commit, getters }, projectId) {
    return api.pages
      .getPageTemplates(getters.getTeamID, {
        ...(projectId && { project_id: projectId }),
      })
      .then(({ data }) => {
        commit('setPageTemplates', data.page_templates || []);
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  fetchPageTemplate({ commit, getters }, templateId) {
    if (!templateId) return Promise.resolve({});

    return api.pages
      .getPageTemplate(getters.getTeamID, templateId)
      .then(({ data: { page_template: pageTemplate } }) => {
        commit('setPageTemplate', pageTemplate || {});
        return pageTemplate;
      })
      .catch((error) => {
        throw error;
      });
  },

  createPageTemplate({ commit, getters }, pageTemplate = getters.getPageTemplateDraft) {
    const tempId = nanoid(4);
    commit('setPageTemplate', {
      ...pageTemplate,
      id: tempId,
      user: { user_id: getters.getUserId },
    });

    return api.pages
      .createPageTemplate(getters.getTeamID, {
        ...pageTemplate,
      })
      .then(({ data }) => {
        commit('replacePageTemplateId', { localId: tempId, template: data.page_template ?? {} });
        return data;
      })
      .catch((error) => {
        commit('deletePageTemplate', tempId);
        throw error;
      });
  },

  updatePageTemplate(
    { commit, getters },
    {
      templateId,
      pageTemplate = getters.getPageTemplateDraft,
      user = { user_id: getters.getUserId },
    }
  ) {
    if (!templateId) return Promise.resolve({});

    const oldTemplate = getters.getPageTemplates[templateId];
    commit('setPageTemplate', {
      id: templateId,
      ...pageTemplate,
      user,
      user_updated: { user_id: getters.getUserId },
    });
    return api.pages
      .updatePageTemplate(getters.getTeamID, templateId, {
        ...pageTemplate,
      })
      .then(({ data }) => {
        commit('setPageTemplate', { id: templateId, ...data.page_template });
        return data;
      })
      .catch((error) => {
        commit('setPageTemplate', { id: templateId, ...oldTemplate });
        throw error;
      });
  },

  deletePageTemplate({ commit, getters }, templateId) {
    if (!templateId) return Promise.resolve({});

    const oldTemplate = getters.getPageTemplates[templateId];
    commit('deletePageTemplate', templateId);
    return api.pages.deletePageTemplate(getters.getTeamID, templateId).catch((error) => {
      commit('setPageTemplate', { id: templateId, ...oldTemplate });
      throw error;
    });
  },

  setPageTemplateDraft({ commit }, template) {
    commit('setPageTemplateDraft', template);
  },

  updatePageTemplateDraftField({ commit }, { fieldName, value }) {
    commit('updatePageTemplateDraftField', { fieldName, value });
  },

  clearPageTemplateDraft({ commit }) {
    commit('clearPageTemplateDraft');
  },

  setHighlightedPageLevelComments({ commit }, commentsIds) {
    commit('setHighlightedPageLevelComments', commentsIds);
  },

  setPageLevelCommentInputActive({ commit }, value) {
    commit('setPageLevelCommentInputActive', value);
  },

  managePageTemplateSelectedSpaces({ commit }, spaceId) {
    commit('managePageTemplateSelectedSpaces', spaceId);
  },

  setPageEditorDisplay({ commit }, payload) {
    commit('setPageEditorDisplay', payload);
  },

  async deletePage({ commit, getters, dispatch }, { pageId, projectId }) {
    const page = getters.getPageById(pageId);
    const pageFromTree = getters.getProjectPagesTree(projectId)[page.id];
    const pages = getters.getPages;

    if (Object.keys(page).length === 0) return Promise.reject(new Error('Page not found'));

    commit('removePageFormProjectPages', {
      pageId,
      projectId,
    });

    commit('localRemovePageFromPageOrder', { projectId, pageId });
    commit('localRemovePagesFromTree', { projectId, pages: [pageFromTree] });
    commit('removeProjectPage', { pageId, projectId });
    commit('removePage', pageId);

    const isFavourite = getters.isFavourite(pageId, ResourceType.Page);
    if (isFavourite) {
      dispatch('deleteUserFavourite', {
        resource_id: pageId,
        resource_type: ResourceType.Page,
        title: page.title,
      });
    }

    return await api.pages
      .deleteAPage(getters.getTeamID, pageId)
      .then(() => {
        // check if page has subpages and if they are favourite
        if (pageFromTree.children) {
          const subpages = Object.values(pageFromTree.children);
          subpages.forEach((subpage: any) => {
            if (getters.isFavourite(subpage, ResourceType.Page)) {
              dispatch('deleteUserFavourite', {
                resource_id: subpage,
                resource_type: ResourceType.Page,
              });
            }
          });
          pageFromTree.children.forEach((childId: any) => {
            if (pages.hasOwnProperty(childId)) {
              delete pages[childId];
            }
          });
        }
        dispatch('removeFromElectronRecentlyViewed', {
          currentlyViewed: { id: pageId, type: ResourceType.Page },
          teamId: getters.getTeamID,
        });
        if (pages?.hasOwnProperty(pageId)) {
          delete pages[pageId];
        }
        dispatch('setPages', pages);
      })
      .catch((error) => {
        commit('addPageToProjectPages', {
          pageId,
          projectId,
        });
        commit('addProjectPage', { page: { id: pageId, title: page.title }, projectId });
        dispatch('addFavourite', {
          resource_id: pageId,
          resource_type: ResourceType.Page,
        });
        throw error;
      });
  },

  removePage({ commit }, pageId) {
    commit('removePage', pageId);
  },

  setPageIdForMove({ commit }, pageId) {
    commit('setPageIdForMove', pageId);
  },
};

export default actions;
