export enum ResourceType {
  Board = 'board',
  List = 'list',
  Card = 'card',
  Page = 'page',
  Project = 'project',
  Space = 'space',
  ChildPage = 'child-page',
  Comment = 'comment',
  CardTemplate = 'cardTemplate',
  PageTemplate = 'pageTemplate',
  NoteTemplate = 'noteTemplate',
  Epic = 'epic',
  View = 'view',
  EpicsBoard = 'epicsBoard',
  InEpicBoard = 'inEpicBoard',
  QuickChildCard = 'quickChildCard',
  Sprints = 'sprints',
  Sprint = 'sprint',
  SprintCard = 'sprintcard',
  CardComment = 'card-comment',
  PageComment = 'page-comment',
  Member = 'member',
  Note = 'note',
  Agent = 'agent',
  AgentPromptIteration = 'agent_prompt_iteration',
}

export enum ResourceLoadedKey {
  Epics = 'epics',
  TeamMembers = 'teamMembers',
  SpaceTags = 'spaceTags',
}
