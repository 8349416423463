import checkRole from './checkRole';
import { TeamMemberRole } from '@superthread-com/api';
import type { Store } from 'vuex';
import { RootState } from '@/types';

export default function (store: Store<RootState>, next: Function, failPath: string = '') {
  const roleCheck = checkRole(
    store,
    {
      pass: undefined,
      fail: { path: failPath },
    },
    { disallowed: [TeamMemberRole.GuestReader] }
  );
  return roleCheck;
}
