/**
 * Main vuex mutations
 * @packageDocumentation
 * @category Store
 */
import { MutationTree } from 'vuex';
import { RootState as State } from '@/types';
import getDefaultState from './state';
import {
  browserStorageGetAndSetItem,
  browserStorageSetItem,
} from '@/utilities/browser-storage.util';
import { myWorkSortByKey } from '@/utilities/localDrafts';
import { MyWorkTabId } from '@/types/tabs';
import { getSprintKey } from '@/utilities/sprintKeys';
import { ResourceType, ResourceLoadedKey } from '@/types/resources';
import { NoteContentType } from '@/types/notes';

const mutations: MutationTree<State> = {
  setTeamProjects(state, { projects, projectOrder }) {
    state.teamProjects = projects;
    if (projectOrder) {
      state.projectOrder = projectOrder;
    }
  },

  setProjectOrder(state, projectOrder) {
    // temp workaround filter out trashed projects
    const newProjectOrder = projectOrder.filter((id: string) => {
      return !state.trashProjects.some((project: any) => project.id === id);
    });
    state.projectOrder = newProjectOrder;
  },

  addToSocketDeletedProjects(state, projectId) {
    state.socketDeletedProjects.push(projectId);
  },

  clearSocketDeletedProjects(state) {
    state.socketDeletedProjects.splice(0, state.socketDeletedProjects.length);
  },

  setUser(state, user: any) {
    state.user = user;
    user.teams.forEach((team: any) => {
      state.teams[team.id] = team;
    });
  },

  setTeam(state, team) {
    const existingTeam = state.teams[team.id];
    state.teams[team.id] = { ...(existingTeam ?? {}), ...team };
  },

  setCommentsSection(state, section) {
    state.commentsSection = section;
  },

  setComments(state, comments) {
    state.comments = comments;
  },

  setCardCommentsAndActivities(state, { cardId, payload }) {
    state.cardsCommentsAndActivities[cardId] = payload;
  },

  addComment(state, comment) {
    const index = state.comments.findIndex((c) => c.id === comment.id);
    if (index > -1) {
      state.comments.splice(index, 1, comment);
    } else {
      state.comments.push(comment);
    }
  },

  setUserName(state, userName: string) {
    state.loginDetails.userName = userName;
  },

  setEmail(state, email: string) {
    state.loginDetails.email = email;
  },

  setPassword(state, password: string) {
    state.loginDetails.password = password;
  },

  setTeamSubDomain(state, teamSubDomain: string) {
    state.loginDetails.teamSubDomain = teamSubDomain;
    localStorage.setItem('teamSubDomain', teamSubDomain);
    if (!teamSubDomain) return;
    if (state.loginDetails.teamSubDomains.includes(teamSubDomain)) return;

    state.loginDetails.teamSubDomains.push(teamSubDomain);
    localStorage.setItem('teamSubDomains', JSON.stringify(state.loginDetails.teamSubDomains));
  },

  removeTeamSubDomain(state, index: number) {
    state.loginDetails.teamSubDomains.splice(index, 1);
    localStorage.setItem('teamSubDomains', JSON.stringify(state.loginDetails.teamSubDomains));
  },

  setLoggedIn(state) {
    state.loggedIn = true;
  },

  setNotLoggedIn(state) {
    state.loggedIn = false;
  },

  setCurrentProjectId(state, { teamId, projectId = '' }) {
    state.currentProjectId = projectId;
    const key = `${teamId}-currentProjectId`;
    localStorage.setItem(key, projectId);
  },

  setCurrentEditCommentID(state, id: string) {
    state.currentEditCommentID = id;
  },

  setPreviousRoute(state, route: object) {
    state.previousRoute = route;
  },

  setPopUpMessage(state, message: string) {
    state.popUpMessage = message;
  },

  setHelpPopupIsOn(state, isOn) {
    state.helpPopupIsOn = isOn;
  },

  setCommentListOrderBy(state, orderBy) {
    state.commentListOrderBy = orderBy;
  },

  setProjectFormName(state, projectFormName) {
    state.projectFormName = projectFormName;
  },

  setProjectFormDescription(state, projectFormDescription) {
    state.projectFormDescription = projectFormDescription;
  },

  setCommentsAreActive(state, active) {
    state.commentsAreActive = active;
  },

  setCurrentTabName(state, tabName) {
    state.currentTabName = tabName;
  },

  itemNotFound(state, value) {
    state.itemNotFound = value;
  },

  setTrashProjects(state, projects) {
    state.trashProjects = projects;
  },

  addLocalComment(state, { comment, index = null, parentCommentId, cardId = null }) {
    let comments = [];
    if (cardId) {
      comments = state.cardsCommentsAndActivities[cardId] ?? [];
    } else {
      comments = state.comments;
    }
    if (parentCommentId) {
      let parentCommentIndex = -1;
      const parentComment = comments.find((c: any, cIndex: number) => {
        if (c.id === parentCommentId) {
          parentCommentIndex = cIndex;
          return c;
        }
        return false;
      });
      if (parentCommentIndex > -1) {
        if (!parentComment.children) {
          parentComment.children = { child_comments: [], count: 0 };
        }
        parentComment.children.child_comments.push(comment);
        parentComment.children.count += 1;
        comments.splice(parentCommentIndex, 1, parentComment);
        return;
      }
    }
    if (index) {
      comments.splice(index, 0, comment);
      return;
    }
    comments.push(comment);
  },

  updateLocalCommentId(
    state,
    { commentId, localId, childCommentId, commentPayload = {}, cardId = null }
  ) {
    const parentCommentId = childCommentId ? commentId : localId;
    const comments = cardId ? state.cardsCommentsAndActivities[cardId] : state.comments;
    let commentIndex: number = -1;
    const comment = comments.find((c: any, cIndex: number) => {
      if (c.id === parentCommentId) {
        commentIndex = cIndex;
        return c;
      }
      return false;
    });
    if (!comment) return;
    if (childCommentId) {
      let childCommentIndex = -1;
      const childComment = comment.children.child_comments.find((childC: any, cIndex: number) => {
        if (childC.id === localId) {
          childCommentIndex = cIndex;
          return childC;
        }
        return false;
      });
      if (childCommentIndex > -1) {
        delete childComment.localCommentId;
        comment.children.child_comments.splice(childCommentIndex, 1, {
          ...childComment,
          ...commentPayload,
        });
      }
      comments.splice(commentIndex, 1, { ...comment });
      if (cardId) {
        state.cardsCommentsAndActivities[cardId] = comments;
      } else {
        state.comments = comments;
      }
      return;
    }
    comments.splice(commentIndex, 1, {
      ...comment,
      ...commentPayload,
      ...{ id: commentId, localCommentId: false },
    });
    if (cardId) {
      state.cardsCommentsAndActivities[cardId] = comments;
    } else {
      state.comments = comments;
    }
  },

  removeLocalComment(state, { localCommentId, parentCommentId, cardId = null }) {
    if (cardId) {
      if (parentCommentId) {
        const parentComment = state.cardsCommentsAndActivities[cardId].find(
          (c: any) => c.id === parentCommentId
        );
        const childCommentIndex = parentComment.children.child_comments.findIndex(
          (c: any) => c.id === localCommentId
        );
        if (childCommentIndex !== -1) {
          parentComment.children.child_comments.splice(childCommentIndex, 1);
          parentComment.children.count -= 1;
          return;
        }
      }
      const comments = state.cardsCommentsAndActivities[cardId];
      const index = comments.findIndex((c: any) => c.id === localCommentId);
      if (index !== -1) {
        comments.splice(index, 1);
      }
      return;
    }
    let { comments: commentsArray } = state;
    if (parentCommentId) {
      const parentComment = state.comments.find((c: any) => c.id === parentCommentId);
      commentsArray = parentComment.children.child_comments;
      parentComment.children.count -= 1;
    }

    const index = commentsArray.findIndex((c: any) => c.id === localCommentId);
    if (index !== -1) {
      commentsArray.splice(index, 1);
    }
  },

  localUpdateComment(state, { fieldName, fieldValue, commentId, childCommentId, cardId = null }) {
    const comments = cardId ? state.cardsCommentsAndActivities[cardId] : state.comments;
    let commentIndex: number = -1;
    const comment = comments.find((c: any, cIndex: number) => {
      if (c.id === commentId) {
        commentIndex = cIndex;
        return c;
      }
      return false;
    });
    if (!comment) return;
    if (!childCommentId) {
      comments.splice(commentIndex, 1, { ...comment, [fieldName]: fieldValue });
    } else {
      let childIndex = -1;
      const childComment = comment.children?.child_comments.find((c: any, cIndex: number) => {
        if (c.id === childCommentId) {
          childIndex = cIndex;
          return c;
        }
        return false;
      });
      comment.children.child_comments.splice(childIndex, 1, {
        ...childComment,
        [fieldName]: fieldValue,
      });
      comments.splice(commentIndex, 1, { ...comment });
    }
    if (cardId) {
      state.cardsCommentsAndActivities[cardId] = comments;
    } else {
      state.comments = comments;
    }
  },

  toggleCommentReaction(state, { commentId, parentResourceId, userId, reaction, cardId = null }) {
    const comments = cardId ? state.cardsCommentsAndActivities[cardId] : state.comments;
    let commentIndex: number = -1;
    const primaryCommentId = parentResourceId || commentId;
    const parentComment = comments.find((c: any, cIndex: number) => {
      if (c.id === primaryCommentId) {
        commentIndex = cIndex;
        return c;
      }
      return false;
    });

    let comment = parentComment;
    let childCommentIndex: number = -1;

    if (parentResourceId) {
      comment = parentComment.children.child_comments.find((c: any, index: number) => {
        if (c.id === commentId) {
          childCommentIndex = index;
          return c;
        }
        return false;
      });
    }

    const storeComment = (comment: any) => {
      if (childCommentIndex > -1) {
        parentComment.children.child_comments.splice(childCommentIndex, 1, { ...comment });
        comments.splice(commentIndex, 1, { ...parentComment });
      } else {
        comments.splice(commentIndex, 1, { ...comment });
      }
      if (cardId) {
        state.cardsCommentsAndActivities[cardId] = comments;
      } else {
        state.comments = comments;
      }
    };

    if (!comment) return;
    const newReaction = {
      id: reaction.id,
      members: [
        {
          user_id: userId,
          variation: reaction.colons,
        },
      ],
      unicode: reaction.native,
    };
    if (!comment.reactions) comment.reactions = [];
    const existingReaction = comment.reactions.find((r: any) => r.id === reaction.id);
    if (existingReaction) {
      // find if user already reacted
      const userAlreadyReacted = existingReaction.members.find((u: any) => u.user_id === userId);
      if (userAlreadyReacted) {
        // remove user from members
        existingReaction.members = existingReaction.members.filter(
          (u: any) => u.user_id !== userId
        );
        if (existingReaction.members.length === 0) {
          // remove reaction when left empty
          comment.reactions = comment.reactions.filter((r: any) => r.id !== reaction.id);
        }
        storeComment(comment);
        return;
      }
      // add user to members
      existingReaction.members.push({
        user_id: userId,
        variation: reaction.colons,
      });
      storeComment(comment);
      return;
    }
    comment.reactions.push(newReaction);
    storeComment(comment);
  },

  setEmojiPickerDisplayed(state, payload) {
    state.emojiPickerDisplayed = payload;
  },

  setCurrentMembers(state, members) {
    state.members = members;
  },

  toggleSearchModal(state, payload) {
    state.showSearchModal = payload;
  },

  setSearchResults(state, results = []) {
    state.searchResults = results;
  },

  setChildSearchResults(state, results = []) {
    state.childSearchResults = results;
  },

  setLastSearchQuery(state, lastSearchQuery) {
    state.lastSearchQuery = lastSearchQuery;
  },

  setNotificationMessage(state, message: string) {
    state.notificationMessage = message;
  },

  setNotificationType(state, type: string) {
    state.notificationType = type;
  },

  toggleDarkMode(state) {
    state.darkMode = !state.darkMode;
  },

  setBoardsBackground(state, value: string) {
    state.boardsBackground = value;
  },

  setTeamProjectField(state, { projectId, fieldName, fieldValue }) {
    const projectIndex = state.teamProjects.findIndex((project: any) => project.id === projectId);
    if (projectIndex > -1) {
      const teamProject = { ...state.teamProjects[projectIndex] };
      teamProject[fieldName] = fieldValue;
      state.teamProjects.splice(projectIndex, 1, teamProject);
    }
  },

  setImportHistory(state, payload) {
    state.importHistory = payload;
  },
  setRedirectProjectId(state, payload) {
    state.redirectProjectId = payload;
  },
  setInviteeRole(state, payload) {
    state.inviteeRole = payload;
  },
  setInviteesProjectIds(state, payload) {
    state.inviteesProjectIds = payload;
  },

  setSidebarExpanded(state, payload) {
    state.sidebarExpanded = payload;
  },

  setAddMemberPopup(state, payload) {
    state.addMemberPopupIsOn = payload;
  },

  setAddMembersTarget(state, target) {
    state.addMembersTarget = target;
  },

  deletePopupId(state, payload) {
    state.deletePopupId = payload;
  },

  setDisplayMembersDropdownId(state, payload) {
    state.displayMembersDropdownId = payload;
  },

  setMembersSearchInput(state, payload) {
    state.membersSearchInput = payload;
  },

  setDisplayEditLinkPopup(state, payload) {
    state.displayEditLinkPopup = payload;
  },

  setDisplayEditorTooltip(state, payload) {
    state.displayEditorTooltip = payload;
  },

  setCommentLinkUrl(state, value) {
    state.commentLinkUrl = value;
  },

  displayInfoSidebar(state, payload) {
    state.displayInfoSidebar = payload;
  },

  resolveInlineComment(state, payload) {
    state.resolveInlineComment = payload;
  },

  setTeamMembers(state, { members, inactive = false }) {
    const membersObj: { [key: string]: any } = {};
    members.forEach((m: any) => {
      membersObj[m.id] = m;
    });
    if (inactive) {
      state.inactiveMembers = membersObj;
      return;
    }
    state.teamMembers = membersObj;
  },

  localAddUserInWorkspace(state, workspace) {
    const i = state.user.teams.findIndex((team: any) => team.id === workspace.id);
    if (i > -1) state.user.teams.splice(i, 1, workspace);
    else state.user.teams.push(workspace);
  },

  locaRemoveUserFromWorkspace(state, teamID) {
    const teamIndex = state?.user?.teams.findIndex((team: { id: any }) => team.id === teamID);
    state?.user?.teams.splice(teamIndex, 1);
  },

  resetState(state) {
    const newState = {
      ...getDefaultState(),
      user: state.user,
      theme: state.theme,
      themePreference: state.themePreference,
      recentWorkspaces: state.recentWorkspaces,
    };
    Object.assign(state, newState);
  },

  addMemberToProject(state, { memberToAdd }) {
    const newMembers = [...state.members, memberToAdd];
    state.members = newMembers;
  },

  removeMemberFromProject(state, { memberToRemove }) {
    const projectMembers = [...state.members];
    const mIndex = projectMembers.findIndex((i) => i.user_id === memberToRemove.user_id);
    projectMembers.splice(mIndex, 1);
    state.members = projectMembers;
  },

  setRedirectRouteAfterLogin(state, route: object) {
    state.redirectRouteAfterLogin = route;
  },

  setNotificationCount(state, count) {
    state.notificationCount = count;
  },

  setNotificationAlerts(state, currentNotifications) {
    state.notificationAlerts = currentNotifications;
  },

  setTeamMemberNotifications(state, notifications) {
    state.teamMemberNotifications = notifications;
  },

  localUpdateNotifMarkAllAsRead(state, notifications) {
    const newNotificationList = notifications.map((n: any) => ({ ...n, read: true }));
    state.teamMemberNotifications = newNotificationList;
  },

  localRemoveNotification(state, notificationId) {
    const index = state.teamMemberNotifications.findIndex((n: any) => n.id === notificationId);
    if (index > -1) {
      state.teamMemberNotifications.splice(index, 1);
    }
  },

  setPublicViews(state, views) {
    state.publicViews = views;
  },

  setPrivateViews(state, views) {
    state.privateViews = views;
  },

  setSelectedViewType(state, viewType) {
    state.selectedViewType = viewType;
  },

  setViewResults(state, viewResults) {
    state.viewPreview = viewResults;
  },

  localAddViewResultsToList(state, { cards, count, id }) {
    state.viewResultsList[id] = { cards, count };
  },

  updateCountInViewResultsList(state, { newCount, id }) {
    state.viewResultsList[id].count = newCount;
  },

  currentViewTotalCount(state, count) {
    state.currentViewTotalCount = count;
  },

  setSelectedView(state, view) {
    state.selectedView = view;
  },

  updateSelectedViewName(state, name) {
    state.selectedView.name = name;
  },

  setViewFilters(state, payload) {
    state.viewFilters = payload;
  },

  setViewPills(state, pills) {
    state.viewPills = pills;
  },

  setViewEditMode(state, changed) {
    state.viewEditMode = changed;
  },

  localAddViewToViewList(state, view) {
    if (view.shared) {
      state.publicViews.unshift(view);
    } else {
      state.privateViews.unshift(view);
    }
  },

  updateLocalViewId(state, { key, viewId }) {
    if (key === 'publicViews') {
      state.publicViews[0].id = viewId;
    } else {
      state.privateViews[0].id = viewId;
    }
  },

  localDeleteView(state, { key, index }) {
    state[key].splice(index, 1);
  },

  localAddView(state, { view, key, index }) {
    state[key].splice(index, 0, view);
  },

  setBoardsFilters(state, boards) {
    state.boardsFilters = boards;
  },
  addUiNotification(state, notification) {
    if (notification.onlineStatus) {
      const index = state.uiNotifications.findIndex((n: any) => n.onlineStatus === false);

      if (index !== -1) {
        state.uiNotifications.splice(index, 1);
      }
    }
    /* Do not push a notification if its `id` is already displayed */
    if (!state.uiNotifications.find((n) => n.id === notification.id)) {
      state.uiNotifications.push(notification);
    }
  },

  removeUiNotificationById(state, notificationId) {
    const index = state.uiNotifications.findIndex((n) => n.id === notificationId);

    if (index > -1) {
      state.uiNotifications.splice(index, 1);
    }
  },

  setFavouritesOpened(state, value) {
    state.favouritesOpened = value;
  },

  setOpenedFavouriteItem(state, favouriteItem) {
    state.openedFavouriteItem = favouriteItem;
  },
  setHeaderMemberDropdownPosition(state, payload) {
    state.headerMemberDropdownPosition = payload;
  },

  setActiveComment(state, payload) {
    state.activeComment = payload;
  },

  updateProjectById(state, project) {
    const index = state.teamProjects.findIndex((p) => p.id === project.id);

    if (index > -1) {
      state.teamProjects.splice(index, 1, project);
    }
  },

  setCurrentRoute(state, route) {
    state.currentRoute = route;
  },

  setSocketConnected(state, value) {
    state.socketConnected = value;
  },
  localUpdateProject(state, { localId, newId }) {
    const projects = [...state.teamProjects];
    const projectIndex = projects.findIndex((i: any) => i.id === localId);
    if (projectIndex > -1) {
      const newProject = { ...projects[projectIndex], id: newId };
      projects.splice(projectIndex, 1, newProject);
      state.teamProjects = projects;
    }
  },
  localUpdateFailRequest(state, localId) {
    const projects = [...state.teamProjects];
    const projectIndex = projects.findIndex((i: any) => i.id === localId);
    if (projectIndex > -1) {
      projects.splice(projectIndex, 1);
      state.teamProjects = projects;
    }
  },

  joinProject(state, { memberId, projectId = state.currentProjectId }) {
    const { user } = state;
    if (memberId === user.id) {
      const projectIndex = state.teamProjects.findIndex((i: any) => i.id === projectId);
      if (projectIndex !== -1) {
        const projects = [...state.teamProjects];
        const updatedProject = { ...state.teamProjects[projectIndex], is_member: true };
        projects.splice(projectIndex, 1, updatedProject);
        state.teamProjects = projects;
      }
    }
  },

  leaveProject(state, { memberId, projectId = state.currentProjectId }) {
    const { user, selectedProjectId } = state;
    const currentProjectId = selectedProjectId !== '' ? selectedProjectId : projectId;
    if (memberId === user.id) {
      const projectIndex = state.teamProjects.findIndex((i: any) => i.id === currentProjectId);
      if (projectIndex !== -1) {
        const projects = [...state.teamProjects];
        const { is_member: isMember, ...rest } = state.teamProjects[projectIndex];
        projects.splice(projectIndex, 1, rest);
        state.teamProjects = projects;
      }
    }
  },

  setDisplayVideoLinkPopup(state, value) {
    state.displayVideoLinkPopup = value;
  },

  setInstallations(state, { integration, installations }) {
    state.installations[integration] = installations;
  },

  addInstallation(state, { integration, installation }) {
    state.installations[integration] = installation;
  },

  setImportProgressSteps(state, payload) {
    state.importProgressSteps = payload;
  },

  updateImportProgressSteps(state, { step, propertyName, value }) {
    state.importProgressSteps[step][propertyName] = value;
  },

  setRouteBeforeSettings(state, value) {
    state.routeBeforeSettings = value;
  },

  setImportProgressCurrentStep(state, value) {
    state.importProgressCurrentStep = value;
  },

  setSettingsCurrentProjectId(state, projectId) {
    state.settingsCurrentProjectId = projectId;
  },

  setCachedComponents(state, components) {
    state.cachedComponents = components;
  },

  setSettingsCurrentBoardId(state, boardId) {
    state.settingsCurrentBoardId = boardId;
  },
  setIntegrationSettings(state, settings) {
    state.integrationSettings = settings;
  },

  updateLocalIntegrationSettings(state, payload) {
    state.integrationSettings = {
      ...state.integrationSettings,
      ...payload,
    };
  },

  setExpandedProject(state, { id, value }) {
    state.projectSidebarExpanded[id] = value;
    browserStorageGetAndSetItem('projectsExpanded', id, value);
  },

  setBoardsColumnLayout(state, { layout, projectId, teamSlug }) {
    const key = `${teamSlug}-${projectId}`;
    state.boardsColumnLayout[key] = layout;
    browserStorageGetAndSetItem('boardsColumnLayout', key, layout);
  },

  setPagesColumnLayout(state, { layout, projectId, teamSlug }) {
    const key = `${teamSlug}-${projectId}`;
    state.pagesColumnLayout[key] = layout;
    browserStorageGetAndSetItem('pagesColumnLayout', key, layout);
  },

  setProjectsExpanded(state, value) {
    state.projectsExpanded = value;
    localStorage.setItem('projSectionExpanded', value);
  },

  setFavouritesExpanded(state, value) {
    state.favouritesExpanded = value;
    localStorage.setItem('favSectionExpanded', value);
  },

  localRemoveBoardOrPageInProject(state, { projectId, itemId, section }) {
    const projectIndex = state.teamProjects.findIndex((i: any) => i.id === projectId);
    if (projectIndex > -1) {
      const itemIndex = state.teamProjects[projectIndex][section].findIndex(
        (i: any) => i.id === itemId
      );
      if (itemIndex > -1) {
        state.teamProjects[projectIndex][section].splice(itemIndex, 1);
      }
    }
  },

  localUpdateProjectBoardOrder(state, { projectToId, projectFromId, boardId, position = -1 }) {
    const projectIndexTo = state.teamProjects.findIndex((i: any) => i.id === projectToId);
    if (
      projectIndexTo > -1 &&
      state.teamProjects[projectIndexTo].board_order.indexOf(boardId) === -1
    ) {
      if (position !== -1) {
        state.teamProjects[projectIndexTo].board_order.splice(position, 0, boardId);
      } else {
        state.teamProjects[projectIndexTo].board_order.push(boardId);
      }
    }

    const projectIndexFrom = state.teamProjects.findIndex((i: any) => i.id === projectFromId);

    if (projectIndexFrom > -1) {
      const boardForRemoveIndex = state.teamProjects[projectIndexFrom].board_order.findIndex(
        (id: any) => id === boardId
      );
      if (boardForRemoveIndex > -1) {
        state.teamProjects[projectIndexFrom].board_order.splice(boardForRemoveIndex, 1);
      }
    }
  },

  localRemoveBoardFromOrder(state, { projectId, boardId }) {
    const projectIndex = state.teamProjects.findIndex((i: any) => i.id === projectId);

    if (projectIndex > -1) {
      const boardForRemoveIndex = state.teamProjects[projectIndex].board_order.findIndex(
        (id: any) => id === boardId
      );
      if (boardForRemoveIndex > -1) {
        state.teamProjects[projectIndex].board_order.splice(boardForRemoveIndex, 1);
      }
    }
  },

  localUpdateProjectBoardsOrder(state, { projectId, payload }) {
    const projectIndex = state.teamProjects.findIndex((i: any) => i.id === projectId);
    if (projectIndex > -1) {
      state.teamProjects[projectIndex].board_order = payload;
    }
  },

  localAddProjectBoardOrder(state, { projectId, boardId }) {
    const projectIndexTo = state.teamProjects.findIndex((i: any) => i.id === projectId);
    if (projectIndexTo > -1 && state.teamProjects[projectIndexTo]) {
      const boardOrder = state.teamProjects[projectIndexTo].board_order;
      if (boardOrder && boardOrder.indexOf(boardId) === -1) {
        boardOrder.push(boardId);
      } else if (!boardOrder) {
        state.teamProjects[projectIndexTo].board_order = [boardId];
      }
    }
  },

  setDraggingEntityProjectFromId(state, id) {
    state.draggingEntityProjectFromId = id;
  },

  setDraggingSidebarItem(state, payload) {
    state.draggingSidebarItem = payload;
  },

  setSelectedProjectId(state, projectId) {
    state.selectedProjectId = projectId;
  },

  setPendingInvites(state, payload) {
    state.pendingInvites = payload;
  },

  setCurrentRole(state, payload) {
    state.currentRole = payload;
  },

  setDoubleCheckPopup(state, payload) {
    state.doubleCheckPopup = payload;
  },

  setConfirmationModal(state, payload) {
    state.confirmationModal = payload;
  },

  addCardIdForLocalId(state, { localId, id }) {
    state.localCardIds[localId] = id;
  },

  setColorsPopup(state, payload) {
    state.colorsPopup = payload;
  },

  setCommentsOrder(state, payload) {
    state.commentsOrder = payload;
  },

  setQuickCard(state, isOn) {
    state.quickCard = isOn;
  },

  setQuickCardBoardId(state, payload) {
    state.quickCardBoardId = payload;
  },

  setQuickPage(state, value) {
    state.quickPage = value;
  },

  setNoteInQuickPage(state, value) {
    state.noteInQuickPage = value;
  },

  setQuickNote(state, value) {
    state.quickNote = value;
  },

  setCardCommentActivityFilter(state, payload) {
    state.cardCommentActivityFilter = payload;
  },

  addCommentInProgressPatch(state, payload) {
    state.commentsInProgressPatch.push(payload);
  },

  removeCommentInProgressPatch(state, payload) {
    const commentIndex = state.commentsInProgressPatch.indexOf(payload);
    state.commentsInProgressPatch.splice(commentIndex, 1);
  },

  addCommentInProgressDelete(state, payload) {
    state.commentsInProgressDelete.push(payload);
  },

  removeCommentInProgressDelete(state, payload) {
    const commentIndex = state.commentsInProgressDelete.indexOf(payload);
    state.commentsInProgressDelete.splice(commentIndex, 1);
  },

  addLoadedFile(state, payload) {
    if (state.loadedFiles.find((file: any) => file.id === payload.id)) return;
    state.loadedFiles.push(payload);
  },

  setOnline(state, value) {
    state.online = value;
  },

  setProjectMembers(state, { members, projectId, projectIndex }) {
    if (projectIndex > -1) {
      state.teamProjects[projectIndex].members = members;
    } else {
      const projectIndex = state.teamProjects.findIndex((i: any) => i.id === projectId);
      if (projectIndex > -1) {
        const newProject = { ...state.teamProjects[projectIndex], members };
        state.teamProjects.splice(projectIndex, 1, newProject);
      }
    }
  },

  setProjectPreviewLastScrollPos(state, { projectId, scrollPosition }) {
    const temp = { ...state.projectPreviewLastScrollPos };
    if (!scrollPosition) {
      delete temp[projectId];
      state.projectPreviewLastScrollPos = temp;
    } else {
      state.projectPreviewLastScrollPos[projectId] = scrollPosition;
    }
  },
  hideAllReplies(state) {
    state.showHideReplies = {};
  },
  showHideReplies(state, payload) {
    state.showHideReplies[payload.commentId] = payload.show;
  },
  closeEditor(state) {
    state.editorOpen = {};
  },
  editorOpen(state, payload) {
    state.editorOpen[payload.commentId] = payload.open;
  },

  setFavourites(state, favourites) {
    const processFavourite = (item: any) => {
      if (item.resource_type === ResourceType.Sprint && !item.resource_id.includes('s')) {
        item.original_resource_id = item.resource_id;
        item.resource_id = getSprintKey(item.project_id, item.resource_id);
      }
      if (item.children) {
        item.children = item.children.map(processFavourite);
      }
      return item;
    };

    const favouritesForSet = favourites.map(processFavourite);
    state.favourites = favouritesForSet;
  },

  addInFavourites(state, item) {
    if (item.position > -1) {
      state.favourites.splice(item.position, 0, item);
    } else {
      state.favourites.push(item);
    }
  },

  addInParentFavourite(state, { item, parentId, position = -1 }) {
    const parentIndex = state.favourites.findIndex((f: any) => f.resource_id === parentId);
    if (state.favourites[parentIndex].children) {
      if (position > -1) {
        state.favourites[parentIndex].children.splice(position, 0, item);
      } else {
        state.favourites[parentIndex].children.push(item);
      }
    } else {
      state.favourites[parentIndex].children = [item];
    }
  },

  localRemoveFromFavourites(state, { resourceId, resourceType }) {
    for (let i = 0; i < state.favourites.length; i++) {
      const favourite = state.favourites[i];
      if (!favourite) continue;
      if (favourite.resource_id === resourceId && favourite.resource_type === resourceType) {
        state.favourites.splice(i, 1);
      } else if (favourite.children) {
        for (let j = 0; j < favourite.children.length; j++) {
          if (
            favourite.children[j].resource_id === resourceId &&
            favourite.children[j].resource_type === resourceType
          ) {
            favourite.children.splice(j, 1);
          }
        }
      }
    }
  },

  localUpdateResourceInFavourites(state, resource) {
    for (let i = 0; i < state.favourites.length; i++) {
      const favourite = state.favourites[i];
      if (
        favourite.resource_id === resource.resource_id &&
        favourite.resource_type === resource.resource_type
      ) {
        state.favourites.splice(i, 1, resource);
      }
      if (favourite?.children) {
        for (let j = 0; j < favourite.children.length; j++) {
          if (
            favourite.children[j].resource_id == resource.resource_id &&
            favourite.children[j].resource_type === resource.resource_type
          ) {
            favourite.children.splice(j, 1, resource);
          }
        }
      }
    }
  },

  setFavouritesFolderExpand(state, { id, value }) {
    state.favouritesFolderExpanded[id] = value;
    let existing = localStorage.getItem('favouritesFolderExpanded');
    existing = existing ? JSON.parse(existing) : {};
    // @ts-ignore: Object is possibly 'null'.
    existing[id] = value;
    localStorage.setItem('favouritesFolderExpanded', JSON.stringify(existing));
  },

  setNewFolderInput(state, value) {
    state.newFolderInput = value;
  },

  setFavouriteFolderForRename(state, value) {
    state.favouriteFolderForRename = value;
  },

  setFavouriteFolderTitle(state, value) {
    state.favouriteFolderTitle = value;
  },
  showPeekSidebar(state, value) {
    state.showPeekSidebar = value;
  },

  setTheme(state, value) {
    state.theme = value;
  },
  addToElectronBrowseHistory(state, payload) {
    if (payload.replaceAfterIndex) {
      state.electronBrowseHistory.splice(payload.replaceAfterIndex + 1);
    }

    state.electronBrowseHistory.push(payload.path);
  },
  setElectronBrowseHistoryIndex(state, value) {
    state.electronBrowseHistoryIndex = value;
  },
  setElectronRecentlyViewed(state, value) {
    state.electronRecentlyViewed = value;
  },
  addToElectronRecentlyViewed(state, payload) {
    const { currentlyViewed, teamId } = payload;
    const { type, id } = currentlyViewed;

    const recentlyViewed = state.electronRecentlyViewed.length
      ? state.electronRecentlyViewed
      : JSON.parse(localStorage.getItem(`recentlyViewed:${teamId}`) ?? '[]');

    const index = recentlyViewed.findIndex((i: any) => i.type === type && i.id === id);

    if (index > -1) {
      recentlyViewed.splice(index, 1);
    }

    recentlyViewed.unshift(currentlyViewed);

    if (recentlyViewed.length > 10) {
      recentlyViewed.pop();
    }

    state.electronRecentlyViewed.splice(0, state.electronRecentlyViewed.length, ...recentlyViewed);

    localStorage.setItem(`recentlyViewed:${teamId}`, JSON.stringify(state.electronRecentlyViewed));
  },
  removeFromElectronRecentlyViewed(state, payload) {
    const { currentlyViewed, teamId } = payload;
    const { type, id } = currentlyViewed;

    const recentlyViewed = state.electronRecentlyViewed.length
      ? state.electronRecentlyViewed
      : JSON.parse(localStorage.getItem(`recentlyViewed:${teamId}`) ?? '[]');

    const index = recentlyViewed.findIndex((i: any) => i.type === type && i.id === id);

    if (index === -1) {
      return;
    }

    recentlyViewed.splice(index, 1);

    state.electronRecentlyViewed.splice(0, state.electronRecentlyViewed.length, ...recentlyViewed);

    localStorage.setItem(`recentlyViewed:${teamId}`, JSON.stringify(recentlyViewed));
  },
  updateItemInElectronRecentlyViewed(
    state,
    { id, type, teamId, toUpdate }: { id: string; type: string; teamId: string; toUpdate: any }
  ) {
    const recentlyViewed = state.electronRecentlyViewed.length
      ? state.electronRecentlyViewed
      : JSON.parse(localStorage.getItem(`recentlyViewed:${teamId}`) ?? '[]');

    const index = recentlyViewed.findIndex((i: any) => i.type === type && i.id === id);

    if (index === -1) {
      return;
    }

    state.electronRecentlyViewed.splice(index, 1, {
      ...recentlyViewed[index],
      ...toUpdate,
    });
    localStorage.setItem(`recentlyViewed:${teamId}`, JSON.stringify(recentlyViewed));
  },
  setThemePreference(state, value) {
    state.themePreference = value;
  },

  setCurrentInboxResource(state, value) {
    state.currentInboxResource = value;
  },

  setInboxFilters(state, value) {
    state.inboxFilter = value;
  },

  setInboxListWidth(state, value) {
    state.inboxListWidth = value;
  },
  setInboxListExpanded(state, value) {
    state.inboxListExpanded = value;
  },

  setShowOnboarding(state, value) {
    state.showOnboarding = value;
  },

  setOpenInDesktopApp(state, value) {
    state.openInDesktopApp = value;
    localStorage.setItem('openInDesktopApp', value);
  },

  setShowRedirectedToAppOverlay(state, value) {
    state.showRedirectedToAppOverlay = value;
  },

  setMembersDropdownInvitees(state, value) {
    state.membersDropdownInvitees = value;
  },

  addMembersDropdownInvitee(state, value) {
    const alreadyAdded = state.membersDropdownInvitees.some(
      (invitee) => (value.id && invitee.id === value.id) || invitee.email === value.email
    );
    if (!alreadyAdded) {
      state.membersDropdownInvitees.push(value);
    }
  },

  removeMembersDropdownInvitee(state, value) {
    const index = state.membersDropdownInvitees.findIndex(
      (invitee) => (value.id && invitee.id === value.id) || invitee.email === value.email
    );
    if (index > -1) state.membersDropdownInvitees.splice(index, 1);
  },

  setEditorForCardLink(state, value) {
    state.editorForCardLink = value;
  },

  setMyWorkSelectedTabId(state, { userId, tabId }: { userId: string; tabId: any }) {
    if (Object.values(MyWorkTabId).indexOf(tabId) === -1) {
      tabId = MyWorkTabId.Assigned;
    }
    state.myWorkSelectedTabId[userId] = tabId;
  },

  setMyWorkUserId(state, userId) {
    state.myWorkUserId = userId;
  },

  addCardToCurrentViewResults(state, card) {
    state.viewPreview.unshift(card);
  },

  setPressedEscCancelDrag(state, value) {
    state.pressedEscCancelDragging = value;
  },

  setSidebarHover(state, value) {
    state.sidebarHovered = value;
  },

  setSidebarDragging(state, value) {
    state.sidebarDragging = value;
  },

  setTeamFeatures(state, features) {
    state.teamFeatures = features;
  },

  updateTeamFeature(state, { featureId, feature }) {
    const index = state.teamFeatures.findIndex((f: any) => f.id === featureId);
    if (index !== -1) {
      state.teamFeatures.splice(index, 1, feature);
    }
  },

  setShowUpdateEmailPopup(state, type) {
    state.showUpdateEmailPopup = type;
  },

  setPageIconPickerDisplayed(state, value) {
    state.pageIconPickerDisplayed = value;
  },

  setHideEmptyGroups(state, { value, boardId }) {
    state.hideEmptyGroups[boardId] = value;
  },

  setShowWeekends(state, value) {
    state.showWeekends = value;
  },

  addReconnectionCallback(state, { callbackId, callback }) {
    state.reconnectionCallbacks[callbackId] = callback;
  },

  removeReconnectionCallback(state, callbackId) {
    delete state.reconnectionCallbacks[callbackId];
  },

  previousNextButtonClicked(state, value) {
    state.previousNextButtonClicked = value;
  },

  // add page to team projects
  addProjectPage(state, { page, projectId }) {
    const projectIndex = state.teamProjects.findIndex((p: any) => p.id === projectId);
    if (projectIndex > -1) {
      const project = state.teamProjects[projectIndex];
      if (project.pages) {
        const pages = [...project.pages];
        pages.push(page);
        project.pages = pages.slice().sort((a: any, b: any) => parseInt(a.id) - parseInt(b.id));
      } else {
        project.pages = [page];
      }
    }
  },

  // remove page from team projects
  removeProjectPage(state, { pageId, projectId }) {
    const projectIndex = state.teamProjects.findIndex((p: any) => p.id === projectId);
    if (projectIndex > -1) {
      const project = state.teamProjects[projectIndex];
      if (project.pages) {
        const pageIndex = project.pages.findIndex((p: any) => p.id === pageId);
        if (pageIndex > -1) {
          project.pages.splice(pageIndex, 1);
        }
      }
    }
  },

  updatePageInTeamProject(state, { projectId, pageId, field, value }) {
    const projectIndex = state.teamProjects.findIndex((p: any) => p.id === projectId);
    if (projectIndex > -1) {
      const project = state.teamProjects[projectIndex];
      if (project.pages) {
        const pageIndex = project.pages.findIndex((p: any) => p.id === pageId);
        if (pageIndex > -1) {
          project.pages[pageIndex][field] = value;
        }
      }
    }
  },

  setUserTimezoneId(state, timezone_id) {
    state.user.timezone_id = timezone_id;
  },

  updateNotificationDigest(state, payload) {
    state.notificationDigest = {
      ...state.notificationDigest,
      ...payload,
    };
  },

  updateNotificationDigestSettings(state, payload) {
    state.notificationDigestSettings = {
      ...state.notificationDigestSettings,
      ...payload,
    };
  },

  setBoardEmbedPopup(state, payload) {
    state.boardEmbedPopup = payload;
  },

  setIsSettingsOpen(state, value) {
    state.isSettingsOpen = value;
  },

  setShowUpgradePlanPopup(state, value) {
    state.showUpgradePlanPopup = value;
  },

  setMyWorkSortBy(state, { teamId, userId, value }) {
    state.myWorkSortBy[userId] = value;
    browserStorageGetAndSetItem(myWorkSortByKey(teamId), userId, value);
  },

  setMyWorkSortByForAllUsers(state, value) {
    state.myWorkSortBy = value;
  },

  localRemoveTeamMember(state, userId) {
    const teamMembers = { ...state.teamMembers };
    delete teamMembers[userId];
    state.teamMembers = teamMembers;
  },

  localUpdateTeamMember(state, { userId, updatedFields }) {
    const teamMembers = { ...state.teamMembers };

    teamMembers[userId] = {
      ...teamMembers[userId],
      ...updatedFields,
    };

    state.teamMembers = teamMembers;
  },

  setModal(state, payload) {
    state.modal = payload;
  },

  setRecentWorkspaces(state, workspace) {
    const workspaceIndex = state.recentWorkspaces.findIndex((ws: any) => ws.id === workspace.id);

    if (workspaceIndex !== -1) state.recentWorkspaces.splice(workspaceIndex, 1);
    state.recentWorkspaces.push(workspace);

    browserStorageSetItem('recentWorkspaces', state.recentWorkspaces);
  },

  updateRecentWorkspace(state, { workspaceId, updatedFields }) {
    const workspaceIndex = state.recentWorkspaces.findIndex((ws: any) => ws.id === workspaceId);
    if (workspaceIndex === -1) return;

    state.recentWorkspaces[workspaceIndex] = {
      ...state.recentWorkspaces[workspaceIndex],
      ...updatedFields,
    };
  },

  setOpenWorkspaceThroughQuickSwitcher(state, value) {
    state.openWorkspaceThroughQuickSwitcher = value;
  },

  setSidebarSprintsExpanded(state, { teamSlug, projectId, value }) {
    state.sidebarSprintsExpanded[projectId] = value;
    let existing = localStorage.getItem(`sidebarSprintsExpanded-${teamSlug}`);
    existing = existing ? JSON.parse(existing) : {};
    // @ts-ignore: Object is possibly 'null'.
    existing[projectId] = value;
    localStorage.setItem(`sidebarSprintsExpanded-${teamSlug}`, JSON.stringify(existing));
  },

  localAddSprintToProject(state, { projectId, sprint }) {
    const projectIndex = state.teamProjects.findIndex((i) => i.id === projectId);

    if (projectIndex > -1) {
      const sprints = state.teamProjects[projectIndex].sprints;
      if (!sprints) {
        state.teamProjects[projectIndex].sprints = [sprint];
      } else if (!sprints.some((s: any) => s.id === sprint.id)) {
        sprints.push(sprint);
      }
    }
  },

  setNote(state, { noteIdToUpdate, note }) {
    if (noteIdToUpdate) {
      delete state.notes[noteIdToUpdate];
    }

    const notes = {
      ...state.notes,
      [note.id]: note,
    };

    state.notes = notes;
  },

  localUpdateNoteProp(state, { noteId, aiNoteId, propertyName, value }) {
    if (!state.notes[noteId]) return;

    if (aiNoteId) {
      const aiNoteIndex = (state.notes[noteId]?.[NoteContentType.AiNotes] || []).findIndex(
        (aiNote: any) => aiNote.id === aiNoteId
      );

      if (aiNoteIndex !== -1) {
        const aiNote = state.notes[noteId][NoteContentType.AiNotes][aiNoteIndex];
        state.notes[noteId][NoteContentType.AiNotes][aiNoteIndex] = {
          ...aiNote,
          [propertyName]: value,
        };
      }

      return;
    }

    const updatedNote = {
      [noteId]: {
        ...state.notes[noteId],
        [propertyName]: value,
      },
    };

    const notes = {
      ...state.notes,
      ...updatedNote,
    };

    state.notes = notes;
  },

  deleteNote(state, noteId) {
    const notes = { ...state.notes };

    delete notes[noteId];
    state.notes = notes;
  },

  setNotes(state, notes) {
    state.notes = notes;
  },

  setAiNoteGenerating(state, noteId) {
    state.aiNoteGenerating = noteId;
  },

  setAiNoteGeneratingTitle(state, noteId) {
    state.aiNoteGeneratingTitle = noteId;
  },

  setAiNote(state, { noteId, templateId, aiNote }) {
    if (!state.notes[noteId]) return;

    const aiNoteIndex = (state.notes[noteId]?.[NoteContentType.AiNotes] || [])?.findIndex(
      (n: any) => n.note_template_id === templateId
    );

    if (aiNoteIndex !== -1) {
      state.notes[noteId][NoteContentType.AiNotes][aiNoteIndex] = aiNote;
      return;
    }

    const aiNotes = state.notes[noteId][NoteContentType.AiNotes] || [];

    aiNotes.push(aiNote);

    state.notes[noteId] = {
      ...state.notes[noteId],
      [NoteContentType.AiNotes]: aiNotes,
    };
  },

  localCreateTranscript(state, { noteId, transcript }) {
    if (!state.notes[noteId]) return;

    if (!state.notes[noteId].transcripts) {
      state.notes[noteId].transcripts = [];
    }

    state.notes[noteId].transcripts.push(transcript);
  },

  localUpdateTranscript(state, { noteId, transcript }) {
    if (!state.notes[noteId]) return;

    const transcriptIndex = state.notes[noteId].transcripts.findIndex(
      (t: any) => t.local_id === transcript.local_id
    );
    if (transcriptIndex !== -1) {
      const updatedTranscript = {
        ...state.notes[noteId].transcripts[transcriptIndex],
        ...transcript,
      };
      state.notes[noteId].transcripts.splice(transcriptIndex, 1, updatedTranscript);
    }
  },

  localSetNoteTranscripts(state, { noteId, transcripts }) {
    if (!state.notes[noteId]) return;

    state.notes[noteId].transcripts = transcripts;
  },

  setTranscriptionState(state, value) {
    state.transcriptionState = { ...state.transcriptionState, ...value };
  },

  setResourceLoaded(state, { resource, value }: { resource: ResourceLoadedKey; value: boolean }) {
    state.resourceLoaded[resource] = value;
  },

  setNoteTemplates(state, templatesArray) {
    const flatTemplates: { [key: string]: any } = {};
    templatesArray.forEach((template: any) => {
      flatTemplates[template.id] = template;
    });
    const newTemplates = { ...flatTemplates };
    state.noteTemplates = newTemplates;
  },

  setNoteTemplate(state, template) {
    state.noteTemplates[template.id] = template;
  },

  replaceNoteTemplateId(state, { localId, template }) {
    const newTemplates = { ...state.noteTemplates };
    delete newTemplates[localId];
    newTemplates[template.id] = template;
    state.noteTemplates = newTemplates;
  },

  deleteNoteTemplate(state, templateId) {
    const newTemplates = { ...state.noteTemplates };
    delete newTemplates[templateId];
    state.noteTemplates = newTemplates;
  },

  setNoteTemplateDraft(state, template) {
    const newTemplate = {
      title: template.title,
      icon: template.icon,
      is_shared: template.is_shared,
      context: template.context,
      sections: template.sections,
    };
    state.noteTemplateDraft = newTemplate;
  },

  updateNoteTemplateDraftField(state, { fieldName, value }) {
    state.noteTemplateDraft[fieldName] = value;
  },

  clearNoteTemplateDraft(state) {
    state.noteTemplateDraft = {
      title: '',
      icon: {},
      is_shared: true,
      context: '',
      sections: [],
    };
  },

  setNoteTemplateSections(state, sections) {
    state.noteTemplateSections = sections;
  },

  setAgents(state, agents) {
    state.agents = agents;
  },

  setCurrentAgentId(state, agentId) {
    state.currentAgentId = agentId;
  },

  setPromptIterationsForAgent(state, { agentId, iterations }) {
    state.agentPromptIterations[agentId] = iterations;
  },

  setAgentRuns(state, agentRuns) {
    state.agentRuns = agentRuns;
  },
};

export default mutations;
