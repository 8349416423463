import { browserStorageGetItem, browserStorageRemoveItem } from '@/utilities/browser-storage.util';

export default function getRouteAfterLogin() {
  // Get the redirect route after login from the browser storage
  const redirectRouteAfterLogin = browserStorageGetItem<any>('redirectRouteAfterLogin') ?? {
    fullPath: '',
    trusted: false,
  };
  // Remove the redirect route after login from the browser storage
  browserStorageRemoveItem('redirectRouteAfterLogin');
  return redirectRouteAfterLogin;
}
