import useShortcutHandlers from '@/utilities/composables/useShortcutHandlers';
import isInViewport from '../utilities/isInViewport';
import EventBus from '../utilities/eventBus';
import checkPlatform from '@/mixins/checkPlatform';

export default {
  mixins: [checkPlatform],
  setup() {
    if (process.env.VUE_APP_ID !== 'public') {
      const { quickCardShortcutHandler, quickPageShortcutHandler, quickNoteShortcutHandler } =
        useShortcutHandlers();
      return { quickCardShortcutHandler, quickPageShortcutHandler, quickNoteShortcutHandler };
    }
  },
  data() {
    return {
      selectedIndex: -1,
      vh: window.innerHeight,
      fieldFocused: false,
      inputBottom: 0,
      suspendMouseEnter: false,
      scrollTop: false,
      enableSpaceKeySelect: false,
      scrolledOnMount: false,
      scrollOnMountTimeout: null,
      updateTimeout: null,
    };
  },
  computed: {
    searchResultsLength() {
      if (!Number.isNaN(parseFloat(this.searchResults?.length), 10)) {
        return this.searchResults.length;
      }
      return 0;
    },
    dropdownMaxHeight() {
      return this.vh - this.inputBottom;
    },
    membersDropdownShown() {
      return this.searchNotEmpty && this.fieldFocused;
    },
    dropdownCalcStyle() {
      // dropdown padding top + bottom = 32
      // viewport edge margin 8
      return { maxHeight: `${this.dropdownMaxHeight - 40}px` };
    },
  },

  mounted() {
    this.getInputYOffset();
  },

  methods: {
    checkKey(event) {
      if (event.target?.dataset?.hiddenInput) {
        if (this.shouldPreventDefault(event)) return;
        // support filtered event keys like shortcuts
        if (this.filteredOutKeyCodes.length) {
          if (this.filteredOutKeyCodes.includes(event.code)) {
            const item = this?.items?.find((i) => i.shortcutKeyCode === event.code);
            if (item) {
              this.selectSearchResult(item);
            }
            return;
          }
        }
      }
      event.stopPropagation();
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
        this.changeSelection(event.key);
      }
      if (event.key === 'Tab') {
        event.preventDefault();
        this.changeSelection(event.key);
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        this.changeSelection(event.key, event);
      }

      if (event.ctrlKey && event.key === '1') {
        event.preventDefault();
        this.hidePopup();
      }
      if (event.ctrlKey && event.key === '2') {
        event.preventDefault();
        this.hidePopup();
      }
      if (event.ctrlKey && event.key === '3') {
        event.preventDefault();
        this.hidePopup();
      }
    },
    checkDownKey(event) {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
      }

      if (event.target.dataset.hiddenInput) {
        if (this.shouldPreventDefault(event)) return;
        // support filtered event keys like shortcuts
        if (this.filteredOutKeyCodes.length) {
          if (this.filteredOutKeyCodes.includes(event.code)) {
            const item = this?.items?.find((i) => i.shortcutKeyCode === event.code);
            if (item) {
              this.selectSearchResult(item);
            }
            return;
          }
        }
      }
      if (event.key === 'Tab') {
        event.preventDefault();
      }
      if (event.code === 'Space' && !event.shiftKey && this.searchResults.length > 0) {
        event.stopPropagation();
        if (this.searchValue === '' || this.enableSpaceKeySelect) {
          event.preventDefault();
          this.changeSelection(event.code);
        }
      }

      if (event.key === 'Escape') {
        event.preventDefault();
        this.handleEsc(event);
      }
      if ((this.isApple ? event.metaKey : event.ctrlKey) && this.shortcutKey(event.key)) {
        event.preventDefault();
        this.handleEsc(event);
        this.displaySearchModal();
      }

      event.stopPropagation();
    },
    shortcutKey(key) {
      return ['K', 'k'].includes(key);
    },
    changeSelection(arrowKey, event = null) {
      if (arrowKey === 'ArrowDown' || arrowKey === 'Tab') {
        this.enableSpaceKeySelect = true;
        this.disableMouseEnter();
        if (this.selectedIndex < this.searchResults?.length - 1) {
          this.selectedIndex += 1;
          return;
        }
        this.selectedIndex = 0;
      }
      if (arrowKey === 'ArrowUp') {
        this.enableSpaceKeySelect = true;
        this.disableMouseEnter();
        if (this.selectedIndex > 0) {
          this.selectedIndex -= 1;
          return;
        }
        this.selectedIndex = this.searchResults?.length - 1;
      }
      if (arrowKey === 'Enter') {
        if (this.selectedIndex > -1) {
          const result = this.searchResults[this.selectedIndex];
          if (result || this.filterIsTextInput) {
            // true for enter key
            this.selectSearchResult(result, this.selectedIndex, event, true);
            if (!result?.category && this.hideFilterPopup) {
              this.hideFilterPopup();
              // don't use select for now, jsut close dropdown
              // EventBus.$emit('enterFilterSelect', result);
            }
          }
        }
      }
      if (arrowKey === 'Space') {
        if (this.selectedIndex > -1) {
          const result = this.searchResults[this.selectedIndex];
          if (result) {
            if (!result.category && typeof this.selectOnlySearchResult === 'function') {
              this.selectOnlySearchResult(result);
              EventBus.$emit('enterFilterSelect', result);
            }
            if (
              !result.category &&
              (result.toggleButton ||
                result.radioButton ||
                result.toggleSection ||
                result.checkBox) &&
              typeof this.toggleItem === 'function'
            ) {
              this.toggleItem(result);
            }
          }
        }
      }
    },
    resultRef(index) {
      return `result-${index}`;
    },
    getInputYOffset() {
      this.$nextTick(() => {
        if (this.$refs.searchInput) {
          let clientRect;
          if (this.$refs.searchInput.$el) {
            clientRect = this.$refs.searchInput.$el.getBoundingClientRect();
          } else {
            clientRect = this.$refs.searchInput.getBoundingClientRect();
          }
          this.inputBottom = clientRect.bottom;
        }
      });
    },
    fieldFocus() {
      this.fieldFocused = true;
    },
    fieldBlur() {
      this.fieldFocused = false;
    },
    handleResize() {
      this.vh = window.innerHeight;
      this.getInputYOffset();
    },
    setSelectedIndex(index) {
      if (this.suspendMouseEnter) {
        return;
      }
      this.selectedIndex = index;
    },
    mouseMoveHandle() {
      this.suspendMouseEnter = false;
    },
    disableMouseEnter() {
      this.suspendMouseEnter = true;
    },
    scrollToTop() {
      this.scrollTop = true;
    },
    displaySearchModal() {
      this.$store.dispatch('setQuickCard', false);
      this.$store.dispatch('setDisplayMembersDropdownId', '');
      this.$store.dispatch('setCurrentCardIdForPopup', '');
      this.$store.dispatch('setQuickPage', false);
      this.$store.dispatch('toggleSearchModal', true);
    },

    compositionUpdate(event) {
      if (this.isMobile) {
        this.$nextTick(() => {
          clearTimeout(this.updateTimeout);
          this.updateTimeout = setTimeout(() => {
            this.searchValue = event.target.value;
          });
        });
      }
    },
    getDropdownElement(resultsRef, newIndex) {
      let el = null;
      const resultsIsVueComponent = resultsRef.every?.((i) => i._isVue);
      if (resultsIsVueComponent) {
        el = resultsRef.find((c) => +(c.$el?.dataset?.index || c.dataset?.index) === newIndex);
      } else {
        el = this.$refs[this.resultRef(newIndex)]?.[0];
      }
      return el;
    },

    scrollToSelectedItem(element, index) {
      const { vertical } = isInViewport(element, this.$refs.searchResultsScroll);
      if (!vertical) {
        if (index === 0 && this.scrollTop && this.$refs.searchResultsScroll) {
          this.$refs.searchResultsScroll.scrollTo({ y: '0%' }, 500);
          this.scrollTop = false;
          return;
        }

        if (!this.scrolledOnMount) {
          this.scrollOnMountTimeout = setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }, 150);
          this.scrolledOnMount = true;
        } else {
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }
    },

    shouldPreventDefault(event) {
      if (
        this.quickCardShortcutHandler?.(event) ||
        this.quickPageShortcutHandler?.(event) ||
        this.quickNoteShortcutHandler?.(event)
      ) {
        event.preventDefault();
        this.handleEsc(event);
        return true;
      }
      return false;
    },
  },

  watch: {
    selectedIndex(newIndex) {
      if (
        Object.prototype.hasOwnProperty.call(this.$refs, 'results') &&
        this.searchResultsLength > 0
      ) {
        const element = this.getDropdownElement(this.$refs.results, newIndex);
        if (!element || Object.keys(element).length === 0) return;

        if (!this.scrolledOnMount) {
          this.scrollOnMountTimeout = setTimeout(() => {
            this.scrollToSelectedItem(element);
          }, 150);
          this.scrolledOnMount = true;
        } else {
          this.scrollToSelectedItem(element);
        }
      }
    },
    searchValue(newValue) {
      if (this.enableSpaceKeySelect) this.enableSpaceKeySelect = false;

      if (newValue) {
        this.selectedIndex = 0;
        return;
      }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    clearTimeout(this.scrollOnMountTimeout);
    clearTimeout(this.updateTimeout);
    window.removeEventListener('resize', this.handleResize);
  },
};
